import PropTypes from 'prop-types';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from '../../components/logo';
import Image from '../../components/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot>


      <StyledSection>


        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={require('src/assets/images/mobile-logo.png')}
          sx={{ maxWidth: 250 }}
        />


        <StyledSectionBg />
      </StyledSection>

      <StyledContent style={{ padding: "0px 64px 0px 64px" }}>
        <div className='test'>

          <Stack sx={{ width: 1 }}> {children} </Stack>
        </div>
      </StyledContent>

    </StyledRoot>
  );
}
