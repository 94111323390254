import { useCallback,useState,useEffect} from 'react';
import { Stack, IconButton, Grid, Box, CardHeader, Tooltip, Dialog, DialogContent, Container, Card, CardContent, Button, TableCell, TableContainer, Table, TableBody, Typography, TableRow } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Scrollbar from "../../components/scrollbar";
import { useTable, TableNoData, TableHeadCustom, TablePaginationCustom, } from "../../components/table";
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';
import { Upload } from 'src/components/upload';
import { useSnackbar } from 'notistack';
import { uploadExceldata } from 'src/service';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import LoadingScreenSmall from 'src/components/loading-screen/LoadingScreenSmall';


export default function ImportData({ open, item, handleClose, filterDetail, dense, order, orderBy, }) {



    return (
        <Dialog
            open={open} onClose={() => { handleClose();}}
            fullWidth
            maxWidth={'md'} >
            <CardHeader
                action={

                    <Tooltip title="Close">
                        <IconButton color='error' className='CloseBtn' onClick={() => {
                            handleClose();
                        }}><Iconify icon="mdi:cancel-circle-outline" />
                        </IconButton>
                    </Tooltip>
                }

                title={"Globa Search "} sx={{ textAlign: "center" }}
            />
        </Dialog >
    );
}

