import PropTypes from "prop-types";
// @mui
import {
  Box,
  Switch,
  TablePagination,
  FormControlLabel,
  Pagination,
} from "@mui/material";

// ----------------------------------------------------------------------

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
};

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100, 200, 500],
  sx,
  ...other
}) {
  return (
    <Box sx={{ position: "relative", ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        {...other}
      />

      <Pagination
        count={Math.ceil(other?.count / other?.rowsPerPage)}
        page={other?.page + 1}
        onChange={other?.onChangePageButton}
        size="large"
        variant="outlined"
        shape="rounded"
        sx={{ marginTop: 1 }}
      />

      {onChangeDense && (
        <FormControlLabel
          label="Dense"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              md: "absolute",
            },
          }}
        />
      )}
    </Box>
  );
}
