import { useState, useEffect } from 'react';
import {
    Box, Divider, Grid, Tooltip, Paper, Button, TextField, MenuItem, CardHeader, Typography, Stack, IconButton, Checkbox, CardContent, Card,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { updateOfflineUsers, deleteOfflineUsers, deleteSocialMediaApi, updateSocialMediaApi } from 'src/service';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Iconify from '../../../../components/iconify';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';
import { useSnackbar } from '../../../../components/snackbar';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useAuthContext } from 'src/auth/useAuthContext';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Image from '../../../../components/image';
import constant from 'src/Constant';
import blankUSer from "../../../../assets/images/blank-profile-picture-973460__340.webp"


const ratingArray = [
    { key: 4, title: "A=80-100%" },
    { key: 3, title: "B=40-80%" },
    { key: 2, title: "C=1-40%" },
    { key: 1, title: "D=0%" },
];

export default function SocialMediaGlobalCard({ check, showPushNotif, selected, handleToggleOne, socialMediaServices, setUpdate, getSocialMediaServices, setShowUpdate, setAdd, update, goToTop, staffNumber, getOffineUsers, show, socialMediaUserList }) {

    const contxt = useAuthContext();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [noteModal, setNoteModal] = useState(false);
    const [mobileModel, setMobileModel] = useState(false);
    const [noteModalValue, setNoteModalValue] = useState("");
    const { copy } = useCopyToClipboard();
    const { enqueueSnackbar } = useSnackbar();
    const [newRow, setNewRow] = useState(socialMediaUserList);
    const [chnageRating, setChangeRating] = useState(false);
    const [ratingID, setRatingID] = useState(null);
    const [mobileValue, setMobileValue] = useState(newRow?.mobile || "");
    const [item, setItem] = useState({ item: update });
    const [note, setNote] = useState([])
    const [date, setDate] = useState([])


    useEffect(() => {
        setNewRow(socialMediaServices)
    }, [socialMediaServices]);

    useEffect(() => {
        setItem({ item: update })
        if (update?.notes) setNote(update?.notes)

    }, [update])

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleCopyMobile = (item) => {
        copy(item);
        enqueueSnackbar('Copied!');
    };

    const styles = {
        smallNormalText: {
            marginTop: "0px",
            fontWeight: 'normal',
            color: 'rgb(105, 105, 105)',
            fontSize: 12.5,
            whiteSpace: "nowrap"
        },
    }




    const onStatus = async (obj) => {
        obj.id = newRow?.id
        let res = await updateSocialMediaApi(obj)
        if (res?.data?.status) {
            enqueueSnackbar(res?.data?.message);
        }
        else enqueueSnackbar(res?.data?.message, { variant: 'error' });
        setNewRow({ ...newRow, ...obj });
    };


    const onDelete = async () => {
        let obj = {
            id: socialMediaServices?.id
        }
        let res = await deleteSocialMediaApi(obj)
        if (res?.data?.status) {
            enqueueSnackbar(res?.data?.message);
            getSocialMediaServices();
        }
        else enqueueSnackbar(res?.data?.message, { variant: 'error' });
    };

    // send whatsapp msg
    const whatsappNow = (mobile) => {
        window.location.href = "https://wa.me/91" + `${mobile}`;
    };
    function callNow(mobile) {
        window.location.href = `tel:${mobile}`;
    }

    useEffect(() => {
        currentDate()
    }, [])

    const currentDate = () => {
        let today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        setDate(today)
    };

    return (
        <>
            <Card>
                <CardContent>
                    {show && <div className="CardNameShow" ><span>Social Media</span></div>}
                    <Grid container spacing={4}>
                        {socialMediaUserList.map((newRow) => {
                            return (
                                <Grid item lg={5} md={6} sm={6} xs={12} >
                                    <div className={`${newRow?.is_online_user ? "offlineCard-Isonline" : "offlineCard"
                                        } m-2`}>
                                        {newRow?.is_online_user && (
                                            <div className="goldenBadge">
                                                <Iconify icon="entypo:shield" />
                                                On App
                                            </div>
                                        )}
                                        <div className='displayBtwPro primaryBg light' style={{ padding: "8px", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}>
                                            <div className={`${newRow?.is_online_user ? "ms-4" : ""}`}>
                                                {newRow.id && <Typography className={`${newRow?.is_online_user ? "ms-4" : ""}`} variant="subtitle1"> #{newRow.id} &nbsp;</Typography>}
                                                {newRow.name && <Typography variant="subtitle1" sx={{ display: "flex", alignItems: "center" }}>
                                                    <AccountCircleIcon className='fs-16' style={{ marginRight: "5px" }} />{newRow.name}
                                                </Typography>}
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                {(check || showPushNotif) && (
                                                    <Checkbox
                                                        size='small'
                                                        checked={selected}
                                                        onChange={() => handleToggleOne(newRow.id)}
                                                    />
                                                )}
                                                {contxt?.user?.sections?.offlineUsers?.update && <EditNoteOutlinedIcon onClick={() => { setUpdate({ ...newRow }); setMobileModel(true); }} />}  </div>
                                        </div>
                                        <div className='p-2 px-3' style={{ background: "#fff" }}>
                                            {/* Header Section start*/}
                                            <div className="row pb-2">
                                                <div className="col-lg-4">
                                                    <span className='secondaryColor fw-500 fs-14 px-2'>ES : {""}<span style={{ color: "#000" }}>{newRow?.SESTAFF?.name?.english}</span></span>
                                                    <hr className='my-1' />
                                                    <span className='fs-12'>{`${moment(newRow?.created_at).format('DD-MM-YY, h:mm')}`}</span>
                                                </div>
                                                {newRow?.whatsapp_status && <div className="col-lg-4">
                                                    <span className='secondaryColor fw-500 fs-14'>WS : {""}<span style={{ color: "#000" }}>{newRow?.SWSTAFF?.name?.english}</span></span>
                                                    <hr className='my-1' />
                                                    <span className='fs-12'>{`${moment(newRow?.whatsapp_date_time).format('DD-MM-YY, h:mm')}`}</span>
                                                </div>}
                                                {newRow?.call_status && <div className="col-lg-4">
                                                    <span className='secondaryColor fw-500 fs-14 px-2'>CS : {""}<span style={{ color: "#000" }}>{newRow?.SCSTAFF?.name?.english}</span></span>
                                                    <hr className='my-1' />
                                                    <span className='fs-12'>{`${moment(newRow?.call_date_time).format('DD-MM-YY, h:mm')}`}</span>
                                                </div>}
                                            </div>
                                            {/* Header Section end */}

                                            {/* Second section start */}
                                            <div className='displayBtwPro'>
                                                <div>{newRow?.mobile?.split(',').map((item) =>
                                                    <Button className='roundBtn' variant="outlined" size='small' onClick={() => { handleCopyMobile(item); }}>{item}
                                                        <ContentCopyOutlinedIcon className='fs-16 ms-2' />
                                                    </Button>)}
                                                </div>
                                                <div>
                                                    <button
                                                        className='WtsBtn'
                                                        style={{
                                                            borderColor: "blue",
                                                            color: newRow?.whatsapp_status ? "#fff" : '#525FFE',
                                                            background: newRow?.whatsapp_status ? '#525FFE' : 'transparent',
                                                        }}
                                                        onClick={() => {
                                                            onStatus({
                                                                whatsapp_status: !newRow?.whatsapp_status,
                                                                whatsapp_date_time: date,
                                                                whatsapp_action_id: contxt?.user?.id,
                                                            });
                                                        }}
                                                    >
                                                        WA
                                                    </button>

                                                    <button className='WtsBtn' style={{ borderColor: "#32BE8F", color: '#32BE8F' }} onClick={() => { whatsappNow(newRow?.mobile) }}><WhatsAppIcon /></button>
                                                    <button className='WtsBtn' style={{ borderColor: "blue", color: 'blue' }} onClick={() => { callNow(newRow?.mobile) }}><CallIcon /></button>
                                                </div>
                                            </div >
                                            {/* Second Section end */}

                                            {/* Third section start */}
                                            <div className='displayBtwPro'>
                                                <Box display="flex" alignItems="center" >
                                                    <Switch checked={newRow?.call_status ? true : false} onChange={() => {
                                                        onStatus({
                                                            call_status: newRow?.call_status ? false : true,
                                                            call_date_time: date,
                                                            calling_action_id: contxt?.user?.id,
                                                        });
                                                    }} />
                                                    <Typography variant="subtitle2" className='primaryColor fw-500 primaryColor'>Call</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" >
                                                    <Switch checked={newRow?.pickup_status ? true : false} onChange={() => {
                                                        onStatus({
                                                            pickup_status: newRow?.pickup_status ? false : true,
                                                            pickup_date_time: date,
                                                        });
                                                    }} />
                                                    <Typography variant="subtitle2" className='primaryColor fw-500 primaryColor'>NP</Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center" >
                                                    <Switch checked={newRow?.not_connected_status ? true : false} onChange={() => {
                                                        onStatus({
                                                            not_connected_status: newRow?.not_connected_status ? false : true,
                                                            not_connected_date_time: date,
                                                        });
                                                    }} />
                                                    <Typography variant="subtitle2" className='primaryColor fw-500 primaryColor'>NC</Typography>
                                                </Box>
                                            </div>
                                            {/* Third section end  */}

                                            {/* Fourth Section start */}
                                            {newRow?.call_status &&
                                                <div>
                                                    <div className="d-flex align-items-center flex-wrap" style={{ gap: "10px" }}>
                                                        <Button size='small' variant={newRow?.sambandh_click ? 'contained' : 'outlined'} className='btnPadding'>Sambandh</Button>
                                                        <Button size='small' variant={newRow?.share_status ? 'contained' : 'outlined'} className='btnPadding'>Share</Button>
                                                        <Button size='small' variant={newRow?.later_status ? 'contained' : 'outlined'} className='btnPadding'>Later</Button>
                                                        <Button size='small' variant={newRow?.special ? 'contained' : 'outlined'} className='btnPadding'>Special</Button>
                                                        <Button size='small' variant={newRow?.not_interested_status ? 'contained' : 'outlined'} className='btnPadding'>Not Interested</Button>
                                                        <Button size='small' variant={newRow?.is_hindu ? 'contained' : 'outlined'} className='btnPadding'>Hindu</Button>
                                                        <Button size='small' variant={newRow?.other_cast ? 'contained' : 'outlined'} className='btnPadding'>Other Cast</Button>
                                                        <Button size='small' variant={newRow?.shadi_done_status ? 'contained' : 'outlined'} className='btnPadding'>Shadi Done</Button>
                                                        <Button size='small' variant={newRow?.online_by_other_mobile_avai ? 'contained' : 'outlined'} className='btnPadding'>On App</Button>
                                                        <Button size='small' variant={newRow?.different_language ? 'contained' : 'outlined'} className='btnPadding'>Diff Language</Button>
                                                    </div>
                                                    {/* rating section start */}
                                                    <div className="my-2">
                                                        {ratingArray?.map((i) => (
                                                            <Button
                                                                className='ratingSec'
                                                                key={i?.key}
                                                                variant={newRow?.rating === i?.key ? "contained" : "outlined"}
                                                                size="small"
                                                            >
                                                                {i?.title}
                                                            </Button>
                                                        ))}
                                                    </div>
                                                    {/* rating section end */}

                                                    {/* notes show start */}
                                                    {/* Sambandh section preview */}
                                                    {newRow?.SOCIALMEDIASAMBAND?.length > 0 &&
                                                        <> {newRow?.SOCIALMEDIASAMBAND?.map((val, ind) => {
                                                            return <div >
                                                                {val?.type == "0" && <div className="row align-items-center">
                                                                    <div className="col-10 pe-0">
                                                                        <p className="fs-12 darkText border-bottom px-1 ">
                                                                            <span style={{ color: "#eb445a" }}><b>SF  </b> </span>
                                                                            {val?.created_for ? ": " + val?.created_for : ""}
                                                                            {val?.name ? "- " + val?.name : ""}
                                                                            {val?.mobile ? "- " + val?.mobile : ""}
                                                                            {val?.relative_name.trim() ? "- " + val?.relative_name : ""}
                                                                            {val?.age ? "- " + val?.age : ""}
                                                                            {val?.employed_in ? "- " + val?.employed_in : ""}
                                                                            {val?.marital_status ? "- " + val?.marital_status : ""}
                                                                            {val?.sect ? "- " + val?.sect : ""}
                                                                            {val?.state ? "- " + val?.state : ""}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                        })}
                                                            {newRow?.SOCIALMEDIASAMBAND && newRow?.SOCIALMEDIASAMBAND?.map((val, ind) => {
                                                                return <div >
                                                                    {val?.type == "1" && <div className="row align-items-center">
                                                                        <div className="col-10">
                                                                            <p className="fs-12 darkText border-bottom px-1 ">
                                                                                <span style={{ color: "#eb445a" }}><b>OF  </b> </span>
                                                                                {val?.created_for ? ": " + val?.created_for : ""}
                                                                                {val?.name ? ": " + val?.name : ""}
                                                                                {val?.mobile ? ": " + val?.mobile : ""}
                                                                                {val?.relative_name ? ": " + val?.relative_name : ""}
                                                                                {val?.employed_in ? "- " + val?.employed_in : ""}
                                                                                {val?.marital_status ? "- " + val?.marital_status : ""}
                                                                                {val?.sect ? "- " + val?.sect : ""}
                                                                                {val?.state ? "- " + val?.state : ""}</p>
                                                                        </div>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            })}
                                                        </>
                                                    }
                                                    <div className="row ">
                                                        <div className="col-12">
                                                            {newRow?.partner_preference ? <span className="fs-12 " ><b style={{ color: "#eb445a" }}>Partner Preference : </b >{newRow?.partner_preference} </span> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-12">
                                                            {newRow?.different_language ? <span className="fs-12 " ><b style={{ color: "#eb445a" }}>Different Language : </b >{newRow?.different_language} | {newRow?.different_language_notes} </span> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-12">
                                                            {newRow?.not_interested_notes ? <span className="fs-12 " ><b style={{ color: "#eb445a" }}>Not Interested Reason : </b >{newRow?.not_interested_notes} </span> : ""}
                                                        </div>
                                                    </div>
                                                    {/* notes show */}
                                                    <div className="row">
                                                        {newRow?.other && (
                                                            <div className="col-12 fs-14" style={{ color: "#525ffe" }}>
                                                                {newRow?.other ? "Notes : " + newRow?.other + newRow?.notes : "" + <br />}
                                                                <span className='fs-12 fw-500' style={{ color: "red" }}> [{newRow?.notes_date_time ? moment(newRow?.notes_date_time)?.format("DD-MM-YY") + " , " + moment(newRow?.notes_date_time)?.format("HH:mm") : ""}]</span>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className="row ">
                                                        {(newRow?.special || newRow?.special_notes) && (
                                                            <div className="col-12 fs-14">
                                                                <span style={{ color: "#525ffe" }}>{newRow?.special ? "Special Share : " + newRow?.special : ""} | </span><span style={{ color: "#EB445A" }}>{newRow?.special_notes ? newRow?.special_notes : ""}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="row">
                                                        {newRow?.normal_share && (
                                                            <div className="col-12">
                                                                {newRow?.normal_share ? "Share : " + newRow?.normal_share : "" + <br />}
                                                            </div>
                                                        )}
                                                    </div>
                                                    {/* notes show end */}

                                                    {/* followup section */}
                                                    {newRow?.later_status &&
                                                        <div className='d-flex' style={{ gap: "20px" }}>
                                                            <div className='fs-14'><span style={{ color: "red" }}>Follow up :</span> {newRow?.follow_date ? <span style={{ fontSize: "13px" }}>Date : {newRow?.follow_date}</span> : <span>Date : --- </span>}</div>
                                                            <div className='fs-14'>{newRow?.follow_time ? <span style={{ fontSize: "13px" }} >Time : {newRow?.follow_time}</span> : <span>Time : ---</span>}</div>
                                                        </div>}

                                                </div>
                                            }
                                            <div>
                                                <hr />
                                                {/* Share to section */}
                                                <div className='ShareSec'>
                                                    {newRow?.share_section ? <p>{newRow?.share_section}</p> : <span>Share to</span>}
                                                </div>

                                                {/* Add Pay/Bio/Pic section */}
                                                <div className='displayBtwPro'>
                                                    <Box display="flex" alignItems="center" >
                                                        <Switch checked={newRow?.biodata_image || newRow?.image ? true : false} />
                                                        <Typography variant="subtitle2" className='primaryColor fw-500 primaryColor'>Add Bio/Pic</Typography>
                                                    </Box>
                                                    <Box display="flex" alignItems="center" >
                                                        <Switch checked={newRow?.online_user_payment_status ? true : false}
                                                        />
                                                        <Typography variant="subtitle2" className='primaryColor fw-500 primaryColor'>Add Pay</Typography>
                                                    </Box>
                                                </div>
                                                <div className='row'>
                                                    <div className="col-6">
                                                        {newRow?.biodata_image || newRow?.image ?
                                                            <div className='row'>
                                                                <div className='col-12'>
                                                                    {newRow?.biodata_image ?
                                                                        <Image alt={newRow.value} src={`${constant.appBaseUrl}/users/offline_users/${newRow?.biodata_image}`} sx={{ borderRadius: 1 }} style={{ width: "120px", height: 100, borderRadius: 8, objectFit: "fill" }} />
                                                                        :
                                                                        <Image src={blankUSer} alt="about-vision" style={{ width: "120px", height: 100, borderRadius: 8, objectFit: "fill" }} />}
                                                                </div>
                                                                <div className='col-12'><div>
                                                                    {newRow?.image ?
                                                                        <Image alt={newRow.value} src={`${constant.appBaseUrl}/users/offline_users/${newRow?.image}`} sx={{ borderRadius: 1 }} style={{ width: "120px", height: 100, borderRadius: 8, objectFit: "fill" }} />
                                                                        :
                                                                        <Image src={blankUSer} alt="about-vision" style={{ width: "120px", height: 100, borderRadius: 8, objectFit: "fill" }} />}
                                                                </div></div>
                                                            </div> : ""}
                                                    </div>
                                                    <div className="col-6">
                                                        {newRow?.online_user_payment_status ?
                                                            <div>
                                                                <div className='ShareSec'>  {newRow?.online_user_package ? <p>{newRow?.online_user_package}</p> : <span>Select...</span>}  </div>
                                                                <p className='my-2' style={{ borderBottom: '1px solid' }}> {newRow?.online_user_done_amount ? <p className='m-0'>{newRow?.online_user_done_amount}</p> : ""}</p>
                                                                {newRow?.online_user_payment_screenshot ?
                                                                    <Image alt={newRow.value} src={`${constant.appBaseUrl}/users/payment_screen_shot/${newRow?.online_user_payment_screenshot}`} sx={{ borderRadius: 1 }} style={{ width: "120px", height: 100, borderRadius: 8, objectFit: "fill" }} />
                                                                    :
                                                                    <Image src={blankUSer} alt="about-vision" style={{ width: "120px", height: 100, borderRadius: 8, objectFit: "fill" }} />}
                                                            </div> : ""}
                                                    </div>
                                                </div>
                                                {/* offline start */}
                                                {newRow?.SofflineResponses?.SoffNoteHistory && newRow?.SofflineResponses?.SoffNoteHistory?.map((val) => {
                                                    return (
                                                        <div className="d-flex align-items-center">
                                                            <div className="chip mx-0 fs-12 py-2 my-1" color="tertiary">
                                                                <span style={{ color: "red" }}>Offline : </span>
                                                                {val?.other}
                                                                {val?.partner_preference}
                                                                <span style={{ color: "#884A39", fontWeight: '500' }}>{val?.notes} </span>
                                                                {val?.notes_date_time ? <span style={{ color: '#EB445A', fontWeight: "500" }}>[{val?.SOffStaffName?.name?.english} {moment(val?.notes_date_time).format("hh:mm")} , {moment(val?.notes_date_time).format("DD/MM/YY")}
                                                                    {val?.SOffStaffName?.public_view === 0 ? (
                                                                        <span style={{ color: "blue" }}>(Old Staff)</span>
                                                                    ) : null}
                                                                    ]</span> : ""}
                                                                {newRow?.SofflineResponses.call_status &&
                                                                    [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Offline Call  :- &nbsp;
                                                                        <span>{moment(newRow?.SofflineResponses.call_date_time).format("hh:mm a")} , {moment(newRow?.SofflineResponses.call_date_time).format("DD/MM/YY")}</span>
                                                                        <span>&nbsp; Name :- {newRow?.SofflineResponses.name}</span></p>]
                                                                }
                                                                {newRow?.SofflineResponses.pickup_status &&
                                                                    [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Offline NP  :- &nbsp;
                                                                        <span>{moment(newRow?.SofflineResponses.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SofflineResponses.pickup_date_time).format("DD/MM/YY")}</span>
                                                                        <span>&nbsp; Name :- {newRow?.SofflineResponses.name}</span></p>]
                                                                }
                                                                {newRow?.SofflineResponses.not_connected_status &&
                                                                    [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Offline NC  :- &nbsp;
                                                                        <span>{moment(newRow?.SofflineResponses.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SofflineResponses.not_connected_date_time).format("DD/MM/YY")}</span>
                                                                        <span>&nbsp; Name :- {newRow?.SofflineResponses.name}</span></p>]
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {newRow?.SofflineResponses ?
                                                    <div style={{ fontWeight: "600", fontSize: "12px" }}>
                                                        {newRow?.SofflineResponses?.call_status && <p className='m-0' style={{ color: 'red' }}  >Offline Call  :- &nbsp;
                                                            <span style={{ color: "Blue" }} >{moment(newRow?.SofflineResponses?.call_date_time).format("hh:mm a")} , {moment(newRow?.SofflineResponses?.call_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "Blue" }}>&nbsp; Name :- {newRow?.SofflineResponses?.SCallOffStaffName?.name?.english}</span></p>}
                                                        {newRow?.SofflineResponses?.pickup_status && <p className='m-0' style={{ color: 'red' }} >Offline NP  :- &nbsp;
                                                            <span style={{ color: "Blue" }}>{moment(newRow?.SofflineResponses?.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SofflineResponses?.pickup_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "Blue" }}>&nbsp; Name :- {newRow?.SofflineResponses?.SCallOffStaffName?.name?.english}</span></p>}
                                                        {newRow?.SofflineResponses?.not_connected_status && <p className='m-0' style={{ color: 'red' }} >Offline NC  :- &nbsp;
                                                            <span style={{ color: "Blue" }}>{moment(newRow?.SofflineResponses?.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SofflineResponses?.not_connected_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "Blue" }}>&nbsp; Name :- {newRow?.SofflineResponses?.SCallOffStaffName?.name?.english}</span></p>}
                                                        {newRow?.SofflineResponses?.normal_share && <span style={{ color: 'red' }} >Offline Share Note : - <span className="textDark"> {newRow?.SofflineResponses?.normal_share} {newRow?.SofflineResponses?.share_notes} &nbsp;</span></span>}
                                                        {newRow?.SofflineResponses?.special_notes && <span style={{ color: 'red' }} >Offline Special Share : - <span className="textDark">{newRow?.SofflineResponses?.special_notes}</span></span>}
                                                        {newRow?.SofflineResponses?.different_language_notes && <p className="m-0" style={{ color: 'red' }} >Offline Different Language : - <span className="textDark">{newRow?.SofflineResponses?.different_language_notes}</span></p>}
                                                        {newRow?.SofflineResponses?.not_interested_notes && <p className="m-0" style={{ color: 'red' }}>Offline Not Interested :- <span className="textDark">{newRow?.SofflineResponses?.not_interested_notes}</span></p>}
                                                        {newRow?.SofflineResponses?.sambandh_custom_notes && <p className="m-0" style={{ color: 'red' }}>Offline Sambandh Custom Note :- <span className="textDark">{newRow?.SofflineResponses?.sambandh_custom_notes}</span></p>}
                                                        {newRow?.SofflineResponses?.sambandh_notes && <p className="m-0" style={{ color: 'red' }}>Offline Sambandh :- <span className="textDark">{newRow?.SofflineResponses?.sambandh_notes}</span></p>}
                                                    </div>
                                                    :
                                                    null}
                                                {/* offline end */}

                                                {/*biodata start */}
                                                {newRow?.SbiodataResponses?.SbiodataNotHistory && newRow?.SbiodataResponses?.SbiodataNotHistory?.map((val) => {
                                                    return (
                                                        <div className="d-flex align-items-center">
                                                            <div className="chip mx-0 fs-12 py-2 my-1" color="tertiary">
                                                                <span style={{ color: "red" }}>Biodata : </span>
                                                                {val?.other}
                                                                <span style={{ color: "#884A39", fontWeight: '500' }}>{val?.notes} </span>
                                                                {val?.notes_date_time ? <span style={{ color: '#EB445A', fontWeight: "500" }}>[{val?.SbiodataStaff?.name?.english} {moment(val?.notes_date_time).format("hh:mm")} , {moment(val?.notes_date_time).format("DD/MM/YY")}
                                                                    {val?.SbiodataStaff?.public_view === 0 ? (
                                                                        <span style={{ color: "blue" }}>(Old Staff)</span>
                                                                    ) : null}
                                                                    ]</span> : ""}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {newRow?.SbiodataResponses ?
                                                    <div style={{ fontWeight: "600", fontSize: "12px" }}>
                                                        {newRow?.SbiodataResponses?.call_status && <p className='m-0' style={{ color: 'red' }}  >Bio Call  :- &nbsp;
                                                            <span style={{ color: "Blue" }} >{moment(newRow?.SbiodataResponses?.call_date_time).format("hh:mm a")} , {moment(newRow?.SbiodataResponses?.call_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "Blue" }} >&nbsp; Name :- {newRow?.SbiodataResponses?.SCallbiodataStaff?.name?.english}</span></p>}
                                                        {newRow?.SbiodataResponses?.pickup_status && <p className='m-0' style={{ color: 'red' }} >Bio NP  :- &nbsp;
                                                            <span style={{ color: "Blue" }}>{moment(newRow?.SbiodataResponses?.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SbiodataResponses?.pickup_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "Blue" }}>&nbsp; Name :- {newRow?.SbiodataResponses?.SCallbiodataStaff?.name?.english}</span></p>}
                                                        {newRow?.SbiodataResponses?.not_connected_status && <p className='m-0' style={{ color: 'red' }} >Bio NC  :- &nbsp;
                                                            <span style={{ color: "Blue" }}>{moment(newRow?.SbiodataResponses?.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SbiodataResponses?.not_connected_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "Blue" }}>&nbsp; Name :- {newRow?.SbiodataResponses?.SCallbiodataStaff?.name?.english}</span></p>}
                                                        {newRow?.SbiodataResponses?.normal_share && <span style={{ color: 'red' }} >Bio Share Note : - <span className="textDark">{newRow?.SbiodataResponses?.normal_share}{newRow?.SbiodataResponses?.share_notes}</span>  &nbsp;</span>}
                                                        {newRow?.SbiodataResponses?.special_notes && <span style={{ color: 'red' }} >Bio Special Share : - <span className="textDark">{newRow?.SbiodataResponses?.special_notes}</span></span>}
                                                        {newRow?.SbiodataResponses?.different_language_notes && <p className="m-0" style={{ color: 'red' }} >Bio Different Language : - <span className="textDark">{newRow?.SbiodataResponses?.different_language_notes}</span></p>}
                                                        {newRow?.SbiodataResponses?.not_interested_notes && <p className="m-0" style={{ color: 'red' }}>Bio Not Interested :- <span className="textDark">{newRow?.SbiodataResponses?.not_interested_notes}</span></p>}
                                                        {newRow?.SbiodataResponses?.sambandh_custom_notes && <p className="m-0" style={{ color: 'red' }}>Bio Sambandh Custom Note :-  <span className="textDark">{newRow?.SbiodataResponses?.sambandh_custom_notes}</span></p>}
                                                        {newRow?.SbiodataResponses?.sambandh_notes && <p className="m-0" style={{ color: 'red' }}>Bio Sambandh :-  <span className="textDark">{newRow?.SbiodataResponses?.sambandh_notes}</span></p>}
                                                    </div>
                                                    :
                                                    null}
                                                {/*biodata end */}

                                                {/* incoming Start */}
                                                {newRow?.SIncoming?.SincomingHistory && newRow?.SIncoming?.SincomingHistory?.map((val) => {
                                                    return (
                                                        <div className="d-flex align-items-center">
                                                            <div className="chip mx-0 fs-12 py-2 my-1" color="tertiary">
                                                                <span style={{ color: "red" }}>Incoming : </span>
                                                                {val?.other}
                                                                {val?.partner_preference}
                                                                <span style={{ color: "#884A39", fontWeight: '500' }}>{val?.notes} </span>
                                                                {val?.notes_date_time ? <span style={{ color: '#EB445A', fontWeight: "500" }}>[{val?.SstaffName?.name?.english}   {moment(val?.notes_date_time).format("hh:mm")} , {moment(val?.notes_date_time).format("DD/MM/YY")}
                                                                    {val?.SstaffName?.public_view === 0 ? (
                                                                        <span style={{ color: "blue" }}>(Old Staff)</span>
                                                                    ) : null}
                                                                    ]</span> : ""}
                                                                {newRow?.SIncoming.call_status &&
                                                                    [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Incoming Call  :- &nbsp;
                                                                        <span>{moment(newRow?.SIncoming.call_date_time).format("hh:mm a")} , {moment(newRow?.SIncoming.call_date_time).format("DD/MM/YY")}</span>
                                                                        <span>&nbsp; Name :- {newRow?.SIncoming.name}</span></p>]
                                                                }
                                                                {newRow?.SIncoming.pickup_status &&
                                                                    [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Incoming NP  :- &nbsp;
                                                                        <span>{moment(newRow?.SIncoming.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SIncoming.pickup_date_time).format("DD/MM/YY")}</span>
                                                                        <span>&nbsp; Name :- {newRow?.SIncoming.name}</span></p>]
                                                                }
                                                                {newRow?.SIncoming.not_connected_status &&
                                                                    [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Incoming NC  :- &nbsp;
                                                                        <span>{moment(newRow?.SIncoming.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SIncoming.not_connected_date_time).format("DD/MM/YY")}</span>
                                                                        <span>&nbsp; Name :- {newRow?.SIncoming.name}</span></p>]
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })}

                                                {newRow?.SIncoming ?
                                                    <div style={{ fontWeight: "600", fontSize: "12px" }}>
                                                        {newRow?.SIncoming?.call_status && <p className='m-0' style={{ color: 'red' }} >Incoming Call  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SIncoming?.call_date_time).format("hh:mm a")} , {moment(newRow?.SIncoming?.call_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SIncoming?.ICallIncomingStaffsName?.name?.english}</span></p>}
                                                        {newRow?.SIncoming?.pickup_status && <p className='m-0' style={{ color: 'red' }} >Incoming NP  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SIncoming?.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SIncoming?.pickup_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SIncoming?.ICallIncomingStaffsName?.name?.english}</span></p>}
                                                        {newRow?.SIncoming?.not_connected_status && <p className='m-0' style={{ color: 'red' }} >Incoming NC  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SIncoming?.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SIncoming?.not_connected_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SIncoming?.ICallIncomingStaffsName?.name?.english}</span></p>}
                                                        {newRow?.SIncoming?.normal_share && <span style={{ color: 'red' }} >Incoming Share Note : - <span className="textDark">{newRow?.SIncoming?.normal_share}{newRow?.SIncoming?.share_notes}</span></span>}
                                                        {newRow?.SIncoming?.special_notes && <span style={{ color: 'red' }} >Incoming Special Share : - <span className="textDark">{newRow?.SIncoming?.special_notes}</span></span>}
                                                        {newRow?.SIncoming?.different_language_notes && <p className="m-0" style={{ color: 'red' }} >Incoming Different Language : - <span className="textDark">{newRow?.SIncoming?.different_language_notes}</span></p>}
                                                        {newRow?.SIncoming?.not_interested_notes && <p className="m-0" style={{ color: 'red' }}>Incoming Not Interested :- <span className="textDark">{newRow?.SIncoming?.not_interested_notes}</span> </p>}
                                                        {newRow?.SIncoming?.sambandh_custom_notes && <p className="m-0" style={{ color: 'red' }}>Incoming Sambandh Custom Note :- <span className="textDark">{newRow?.SIncoming?.sambandh_custom_notes}</span></p>}
                                                        {newRow?.SIncoming?.sambandh_notes && <p className="m-0" style={{ color: 'red' }}>Incoming Sambandh :- <span className="textDark">{newRow?.SIncoming?.sambandh_notes}</span></p>}
                                                    </div>
                                                    :
                                                    null}
                                                {/* incoming end */}

                                                {/* whatsapp start */}
                                                <p className='m-0'>
                                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "red" }}>
                                                        Whatsapp Response :{" "}
                                                    </span>
                                                    {(newRow?.SWhatsRes?.SWhatsReserHist?.length) &&
                                                        <>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SWhatsRes.SWhatsReserHist[newRow.SWhatsRes.SWhatsReserHist.length - 1]?.partner_preference}</span>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SWhatsRes.SWhatsReserHist[newRow.SWhatsRes.SWhatsReserHist.length - 1]?.other}</span>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SWhatsRes.SWhatsReserHist[newRow.SWhatsRes.SWhatsReserHist.length - 1]?.notes}</span>
                                                            {(newRow.SWhatsRes.SWhatsReserHist[newRow.SWhatsRes.SWhatsReserHist.length - 1]?.notes_date_time) &&
                                                                <span style={{ color: "blue", fontWeight: 500, textTransform: "capitalize", fontSize: "12px" }}>
                                                                    [<span>{newRow.SWhatsRes.SWhatsReserHist[newRow.SWhatsRes.SWhatsReserHist.length - 1]?.SWstaffName?.name?.english} - </span>{moment(newRow.SWhatsRes.SWhatsReserHist[newRow.SWhatsRes.SWhatsReserHist.length - 1]?.notes_date_time).format('DD-MM-YY, h:mm a')}
                                                                    {newRow.SWhatsRes.SWhatsReserHist[newRow.SWhatsRes.SWhatsReserHist.length - 1]?.SWstaffName?.public_view === 0 ? (
                                                                        <span style={{ color: "red" }}>(Old Staff)</span>
                                                                    ) : null}
                                                                    ]
                                                                </span>}
                                                            {newRow?.SWhatsRes.call_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Whatsapp Response Call  :- &nbsp;
                                                                    <span>{moment(newRow?.SWhatsRes.call_date_time).format("hh:mm a")} , {moment(newRow?.SWhatsRes.call_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SWhatsRes.name}</span></p>]
                                                            }
                                                            {newRow?.SWhatsRes.pickup_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Whatsapp Response NP  :- &nbsp;
                                                                    <span>{moment(newRow?.SWhatsRes.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SWhatsRes.pickup_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SWhatsRes.name}</span></p>]
                                                            }
                                                            {newRow?.SWhatsRes.not_connected_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Whatsapp Response NC  :- &nbsp;
                                                                    <span>{moment(newRow?.SWhatsRes.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SWhatsRes.not_connected_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SWhatsRes.name}</span></p>]
                                                            }
                                                        </>
                                                    }
                                                </p>
                                                {newRow?.SWhatsRes ?
                                                    <div style={{ fontWeight: "600", fontSize: "12px" }}>
                                                        {newRow?.SWhatsRes?.call_status && <p className='m-0' style={{ color: 'red' }} >Whatsapp Call  :- &nbsp;
                                                            <span style={{ color: "blue" }}>{moment(newRow?.SWhatsRes?.call_date_time).format("hh:mm a")} , {moment(newRow?.SWhatsRes?.call_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "blue" }}>&nbsp; Name :- {newRow?.SWhatsRes?.ICallWhatsResStaffsName?.name?.english}</span></p>}
                                                        {newRow?.SWhatsRes?.pickup_status && <p className='m-0' style={{ color: 'red' }} >Whatsapp NP  :- &nbsp;
                                                            <span style={{ color: "blue" }}>{moment(newRow?.SWhatsRes?.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SWhatsRes?.pickup_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "blue" }}>&nbsp; Name :- {newRow?.SWhatsRes?.ICallWhatsResStaffsName?.name?.english}</span></p>}
                                                        {newRow?.SWhatsRes?.not_connected_status && <p className='m-0' style={{ color: 'red' }} >Whatsapp NC  :- &nbsp;
                                                            <span style={{ color: "blue" }}>{moment(newRow?.SWhatsRes?.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SWhatsRes?.not_connected_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: "blue" }}>&nbsp; Name :- {newRow?.SWhatsRes?.ICallWhatsResStaffsName?.name?.english}</span></p>}
                                                        {newRow?.SWhatsRes?.normal_share && <span style={{ color: 'red' }} >Whatsapp Share Note : - <span className="textDark">{newRow?.SWhatsRes?.normal_share}{newRow?.SWhatsRes?.share_notes}</span> &nbsp;</span>}
                                                        {newRow?.SWhatsRes?.special_notes && <span style={{ color: 'red' }} >Whatsapp Special Share : - <span className="textDark">{newRow?.SWhatsRes?.special_notes}</span></span>}
                                                        {newRow?.SWhatsRes?.different_language_notes && <p className="m-0" style={{ color: 'red' }} >Whatsapp Different Language : - <span className="textDark">{newRow?.SWhatsRes?.different_language_notes}</span></p>}
                                                        {newRow?.SWhatsRes?.not_interested_notes && <p className="m-0" style={{ color: 'red' }}>Whatsapp Not Interested :- <span className="textDark">{newRow?.SWhatsRes?.not_interested_notes}</span></p>}
                                                        {newRow?.SWhatsRes?.sambandh_custom_notes && <p className="m-0" style={{ color: 'red' }}>Whatsapp Sambandh Custom Note :- <span className="textDark">{newRow?.SWhatsRes?.sambandh_custom_notes}</span></p>}
                                                        {newRow?.SWhatsRes?.sambandh_notes && <p className="m-0" style={{ color: 'red' }}>Whatsapp Sambandh :- <span className="textDark">{newRow?.SWhatsRes?.sambandh_notes}</span></p>}
                                                    </div>
                                                    :
                                                    null}
                                                {/* whatsapp end */}

                                                {/* group start */}
                                                <p className='m-0'>
                                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "red" }}>
                                                        Whatsapp Group Service :{" "}
                                                    </span>
                                                    {(newRow?.SGroupS?.SGroupSer?.length) &&
                                                        <>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SGroupS.SGroupSer[newRow.SGroupS.SGroupSer.length - 1]?.partner_preference}</span>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SGroupS.SGroupSer[newRow.SGroupS.SGroupSer.length - 1]?.other}</span>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SGroupS.SGroupSer[newRow.SGroupS.SGroupSer.length - 1]?.notes}</span>
                                                            {(newRow.SGroupS.SGroupSer[newRow.SGroupS.SGroupSer.length - 1]?.notes_date_time) &&
                                                                <span style={{ color: "blue", fontWeight: 500, textTransform: "capitalize", fontSize: "12px" }}>
                                                                    [<span>{newRow.SGroupS.SGroupSer[newRow.SGroupS.SGroupSer.length - 1]?.SGstaffName?.name?.english} - </span>{moment(newRow.SGroupS.SGroupSer[newRow.SGroupS.SGroupSer.length - 1]?.notes_date_time).format('DD-MM-YY, h:mm a')}
                                                                    {newRow.SGroupS.SGroupSer[newRow.SGroupS.SGroupSer.length - 1]?.SGstaffName?.public_view === 0 ? (
                                                                        <span style={{ color: "red" }}>(Old Staff)</span>
                                                                    ) : null}
                                                                    ]
                                                                </span>}
                                                            {newRow?.SGroupS.call_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Whatsapp Group Service Call  :- &nbsp;
                                                                    <span>{moment(newRow?.SGroupS.call_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS.call_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SGroupS.name}</span></p>]
                                                            }
                                                            {newRow?.SGroupS.pickup_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Whatsapp Group Service NP  :- &nbsp;
                                                                    <span>{moment(newRow?.SGroupS.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS.pickup_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SGroupS.name}</span></p>]
                                                            }
                                                            {newRow?.SGroupS.not_connected_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Whatsapp Group Service NC  :- &nbsp;
                                                                    <span>{moment(newRow?.SGroupS.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS.not_connected_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SGroupS.name}</span></p>]
                                                            }
                                                        </>
                                                    }
                                                </p>
                                                {newRow?.SGroupS ?
                                                    <div style={{ fontWeight: "600", fontSize: "12px" }}>
                                                        {newRow?.SGroupS?.call_status && <p className='m-0' style={{ color: 'red' }} >Group Call  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SGroupS?.call_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS?.call_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SGroupS?.SCallGstaffName?.name?.english}</span></p>}
                                                        {newRow?.SGroupS?.pickup_status && <p className='m-0' style={{ color: 'red' }} >Group NP  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SGroupS?.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS?.pickup_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SGroupS?.SCallGstaffName?.name?.english}</span></p>}
                                                        {newRow?.SGroupS?.not_connected_status && <p className='m-0' style={{ color: 'red' }} >Group NC  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SGroupS?.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS?.not_connected_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SGroupS?.SCallGstaffName?.name?.english}</span></p>}
                                                        {newRow?.SGroupS?.normal_share && <span style={{ color: 'red' }} >Group Share Note : - <span className="textDark">{newRow?.SGroupS?.normal_share}{newRow?.SGroupS?.share_notes}</span></span>}
                                                        {newRow?.SGroupS?.special_notes && <span style={{ color: 'red' }} >Group Special Share : - <span className="textDark">{newRow?.SGroupS?.special_notes}</span></span>}
                                                        {newRow?.SGroupS?.different_language_notes && <p className="m-0" style={{ color: 'red' }} >Group Different Language : -<span className="textDark">{newRow?.SGroupS?.different_language_notes}</span> </p>}
                                                        {newRow?.SGroupS?.not_interested_notes && <p className="m-0" style={{ color: 'red' }}>Group Not Interested :- <span className="textDark">{newRow?.SGroupS?.not_interested_notes}</span></p>}
                                                        {newRow?.SGroupS?.sambandh_custom_notes && <p className="m-0" style={{ color: 'red' }}>Group Sambandh Custom Note :- <span className="textDark">{newRow?.SGroupS?.sambandh_custom_notes}</span></p>}
                                                        {newRow?.SGroupS?.sambandh_notes && <p className="m-0" style={{ color: 'red' }}>Group Sambandh :- <span className="textDark">{newRow?.SGroupS?.sambandh_notes}</span></p>}
                                                    </div>
                                                    :
                                                    null}
                                                {/* group end */}

                                                {/* paid start */}
                                                <p className='m-0'>
                                                    <span style={{ fontSize: "12px", fontWeight: "500", color: "red" }}>
                                                        Paid Promotion :{" "}
                                                    </span>
                                                    {(newRow?.SPaidPa?.SPaidPhistory?.length) &&
                                                        <>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SPaidPa.SPaidPhistory[newRow.SPaidPa.SPaidPhistory.length - 1]?.partner_preference}</span>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SPaidPa.SPaidPhistory[newRow.SPaidPa.SPaidPhistory.length - 1]?.other}</span>
                                                            <span style={{ color: "#000", fontWeight: 400, fontSize: "12px" }}>{newRow.SPaidPa.SPaidPhistory[newRow.SPaidPa.SPaidPhistory.length - 1]?.notes}</span>
                                                            {(newRow.SPaidPa.SPaidPhistory[newRow.SPaidPa.SPaidPhistory.length - 1]?.notes_date_time) &&
                                                                <span style={{ color: "blue", fontWeight: 500, textTransform: "capitalize", fontSize: "12px" }}>
                                                                    [<span>{newRow.SPaidPa.SPaidPhistory[newRow.SPaidPa.SPaidPhistory.length - 1]?.SPstaffName?.name?.english} - </span>{moment(newRow.SPaidPa.SPaidPhistory[newRow.SPaidPa.SPaidPhistory.length - 1]?.notes_date_time).format('DD-MM-YY, h:mm a')}
                                                                    {newRow.SPaidPa.SPaidPhistory[newRow.SPaidPa.SPaidPhistory.length - 1]?.SPstaffName?.public_view === 0 ? (
                                                                        <span style={{ color: "red" }}>(Old Staff)</span>
                                                                    ) : null}
                                                                    ]
                                                                </span>}
                                                            {newRow?.SPaidPa.call_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Paid Promotion Call  :- &nbsp;
                                                                    <span>{moment(newRow?.SPaidPa.call_date_time).format("hh:mm a")} , {moment(newRow?.SPaidPa.call_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SPaidPa.name}</span></p>]
                                                            }
                                                            {newRow?.SPaidPa.pickup_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Paid Promotion NP  :- &nbsp;
                                                                    <span>{moment(newRow?.SPaidPa.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SPaidPa.pickup_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SPaidPa.name}</span></p>]
                                                            }
                                                            {newRow?.SPaidPa.not_connected_status &&
                                                                [<p className='m-0' style={{ color: 'blue', fontWeight: "500", fontSize: "12px" }} >Paid Promotion NC  :- &nbsp;
                                                                    <span>{moment(newRow?.SPaidPa.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SPaidPa.not_connected_date_time).format("DD/MM/YY")}</span>
                                                                    <span>&nbsp; Name :- {newRow?.SPaidPa.name}</span></p>]
                                                            }
                                                        </>
                                                    }
                                                </p>
                                                {newRow?.SPaidPa ?
                                                    <div style={{ fontWeight: "600", fontSize: "12px" }}>
                                                        {newRow?.SPaidPa?.call_status && <p className='m-0' style={{ color: 'red' }} >Paid Call  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SPaidPa?.call_date_time).format("hh:mm a")} , {moment(newRow?.SPaidPa?.call_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SPaidPa?.SCallPstaffName?.name?.english}</span></p>}
                                                        {newRow?.SPaidPa?.pickup_status && <p className='m-0' style={{ color: 'red' }} >Paid NP  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SPaidPa?.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SPaidPa?.pickup_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SPaidPa?.SCallPstaffName?.name?.english}</span></p>}
                                                        {newRow?.SPaidPa?.not_connected_status && <p className='m-0' style={{ color: 'red' }} >Paid NC  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SPaidPa?.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SPaidPa?.not_connected_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SPaidPa?.SCallPstaffName?.name?.english}</span></p>}
                                                        {newRow?.SPaidPa?.normal_share && <span style={{ color: 'red' }} >Paid Share Note : - <span className="textDark">{newRow?.SPaidPa?.normal_share}{newRow?.SPaidPa?.share_notes}</span></span>}
                                                        {newRow?.SPaidPa?.special_notes && <span style={{ color: 'red' }} >Paid Special Share : - <span className="textDark">{newRow?.SPaidPa?.special_notes}</span></span>}
                                                        {newRow?.SPaidPa?.different_language_notes && <p className="m-0" style={{ color: 'red' }} >Paid Different Language : - <span className="textDark">{newRow?.SPaidPa?.different_language_notes}</span></p>}
                                                        {newRow?.SPaidPa?.not_interested_notes && <p className="m-0" style={{ color: 'red' }}>Paid Not Interested :- <span className="textDark">{newRow?.SPaidPa?.not_interested_notes}</span></p>}
                                                        {newRow?.SPaidPa?.sambandh_custom_notes && <p className="m-0" style={{ color: 'red' }}>Paid Sambandh Custom Note :- <span className="textDark">{newRow?.SPaidPa?.sambandh_custom_notes}</span></p>}
                                                        {newRow?.SPaidPa?.sambandh_notes && <p className="m-0" style={{ color: 'red' }}>Paid Sambandh :- <span className="textDark">{newRow?.SPaidPa?.sambandh_notes}</span></p>}
                                                    </div>
                                                    :
                                                    null}
                                                {/* paid end */}

                                                {/* social start */}
                                                {newRow?.SocialMUserNote && newRow?.SocialMUserNote?.map((val) => {
                                                    return (
                                                        <div className="d-flex align-items-center">
                                                            <div className='fs-14'>
                                                                <span className='fw-500' style={{ color: "red" }}>Social Media :-</span>
                                                                {val?.other}
                                                                <span style={{ color: "#884A39", fontWeight: '500' }}>{val?.notes} </span>
                                                                {val?.notes_date_time ? <span style={{ color: '#EB445A', fontWeight: "500" }}>[{val?.NoteByStaffSocialM?.name?.english}  {moment(val?.notes_date_time).format("hh:mm")} , {moment(val?.notes_date_time).format("DD/MM/YY")}
                                                                    {val?.NoteByStaffSocialM?.public_view === 0 ? (
                                                                        <span style={{ color: "blue" }}>(Old Staff)</span>
                                                                    ) : null}
                                                                    ]</span> : ""}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {newRow?.SGroupS ?
                                                    <div style={{ fontWeight: "600", fontSize: "12px" }}>
                                                        {newRow?.SGroupS?.call_status && <p className='m-0' style={{ color: 'red' }} >Social Call  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SGroupS?.call_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS?.call_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SGroupS?.SCallGstaffName?.name?.english}</span></p>}
                                                        {newRow?.SGroupS?.pickup_status && <p className='m-0' style={{ color: 'red' }} >Social NP  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SGroupS?.pickup_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS?.pickup_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SGroupS?.SCallGstaffName?.name?.english}</span></p>}
                                                        {newRow?.SGroupS?.not_connected_status && <p className='m-0' style={{ color: 'red' }} >Social NC  :- &nbsp;
                                                            <span style={{ color: 'blue' }}>{moment(newRow?.SGroupS?.not_connected_date_time).format("hh:mm a")} , {moment(newRow?.SGroupS?.not_connected_date_time).format("DD/MM/YY")}</span>
                                                            <span style={{ color: 'blue' }}>&nbsp; Name :- {newRow?.SGroupS?.SCallGstaffName?.name?.english}</span></p>}
                                                        {newRow?.SGroupS?.normal_share && <span style={{ color: 'red' }} >Social Share Note : - <span className="textDark">{newRow?.SGroupS?.normal_share}{newRow?.SGroupS?.share_notes}</span></span>}
                                                        {newRow?.SGroupS?.special_notes && <span style={{ color: 'red' }} >Social Special Share : - <span className="textDark">{newRow?.SGroupS?.special_notes}</span></span>}
                                                        {newRow?.SGroupS?.different_language_notes && <p className="m-0" style={{ color: 'red' }} >Social Different Language : - <span className="textDark">{newRow?.SGroupS?.different_language_notes}</span></p>}
                                                        {newRow?.SGroupS?.not_interested_notes && <p className="m-0" style={{ color: 'red' }}>Social Not Interested :- <span className="textDark">{newRow?.SGroupS?.not_interested_notes}</span></p>}
                                                        {newRow?.SGroupS?.sambandh_custom_notes && <p className="m-0" style={{ color: 'red' }}>Social Sambandh Custom Note :- <span className="textDark">{newRow?.SGroupS?.sambandh_custom_notes}</span></p>}
                                                        {newRow?.SGroupS?.sambandh_notes && <p className="m-0" style={{ color: 'red' }}>Social Sambandh :- <span className="textDark">{newRow?.SGroupS?.sambandh_notes}</span></p>}
                                                    </div>
                                                    :
                                                    null}
                                                {/* social end */}
                                                {/* Note Section */}
                                                <div>
                                                    <Box display="flex" sx={{ background: "#f2f2fe", color: "blue", padding: "5px", borderRadius: "8px" }}>
                                                        <Typography variant="subtitle2" >  Note : &nbsp;</Typography>
                                                        {newRow?.notes && <Typography variant="subtitle2" >
                                                            {newRow?.notes}
                                                        </Typography>}
                                                    </Box>
                                                    <Box display="flex" sx={{ padding: "5px" }}>
                                                        <Typography variant="overline" >  Cost : &nbsp;</Typography>
                                                        {newRow?.final_cost && <Typography variant="subtitle2" sx={{ fontWeight: 'normal', color: 'rgb(105, 105, 105)', fontSize: 12.5, color: 'text.secondary' }}>
                                                            {newRow?.final_cost}
                                                        </Typography>}
                                                    </Box>
                                                </div>
                                            </div>
                                            {/* Fourth Section end */}

                                            {/* Last Button Section start */}
                                            <Grid container sx={{ textAlign: 'center', padding: '10px 0px' }} >
                                                {contxt?.user?.sections?.offlineUsers?.delete ?
                                                    <Grid item lg={3} md={3} sm={6} xs={6} mb={{ sm: 1, xs: 1 }}>
                                                        <Button variant="contained" size='small' onClick={() => { handleOpenConfirm(); }} >
                                                            Delete
                                                        </Button>
                                                    </Grid> :
                                                    <></>}
                                                {contxt?.user?.sections?.offlineUsers?.update ? <Grid item lg={3} md={3} sm={6} xs={6} >
                                                    <Button variant="contained" color="inherit" size='small' onClick={() => { setUpdate({ ...newRow }); setShowUpdate(false); goToTop(); }} >
                                                        Update
                                                    </Button>
                                                </Grid> :
                                                    <></>}

                                                {contxt?.user?.sections?.offlineUsers?.update ?
                                                    <>
                                                        <Grid item lg={3} md={3} sm={6} xs={6} >
                                                            <Button variant="contained" color="inherit" size='small' onClick={(event) => {
                                                                event.stopPropagation();
                                                                setNoteModal(true);
                                                                setUpdate({ ...newRow });
                                                            }}>
                                                                Notes
                                                            </Button>
                                                        </Grid>

                                                    </>
                                                    : <></>}
                                            </Grid>
                                            {/* Last Button Section end  */}
                                        </div>
                                        <ConfirmDialog className="deleteModal"
                                            open={openConfirm}
                                            onClose={handleCloseConfirm}
                                            title="Delete"
                                            content="Are you sure want to delete?"
                                            action={
                                                <div>
                                                    <Button variant="outlined" color="inherit" onClick={handleCloseConfirm} style={{ marginRight: 10 }}>
                                                        Cancel
                                                    </Button>
                                                    <Button variant="contained" color="error" onClick={() => {
                                                        onDelete();
                                                        handleCloseConfirm();
                                                    }}>
                                                        Delete
                                                    </Button>
                                                </div>
                                            }
                                        />
                                        <ConfirmDialog
                                            className="onlineModal"
                                            maxWidth={'sm'}
                                            open={mobileModel}
                                            onClose={() => {
                                                setMobileModel(false)
                                            }}
                                            content={
                                                <>
                                                    <CardHeader style={{ padding: "15px 0px", }}
                                                        action={
                                                            <Tooltip title="Close">
                                                                <IconButton color='error' className='CloseBtn' onClick={() => {
                                                                    setMobileModel(false)
                                                                }}><Iconify icon="mdi:cancel-circle-outline" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        title={"Edit Mobile"}
                                                    />

                                                    <div>
                                                        <TextField multiline fullWidth label="Mobile" type="number" placeholder='Type  here..' value={mobileValue} onChange={(e) => {
                                                            setMobileValue(e.target.value)
                                                        }} />
                                                        <div style={{ textAlign: "center" }}>
                                                            <LoadingButton
                                                                color="inherit"
                                                                size="large"
                                                                type="submit"
                                                                loadingPosition="center"
                                                                variant="contained"
                                                                sx={{
                                                                    mt: 5, mb: 3, width: "30%",
                                                                    bgcolor: 'text.primary', typography: 'body1',
                                                                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                                                    '&:hover': {
                                                                        bgcolor: 'text.primary',
                                                                        color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    onStatus({
                                                                        mobile: mobileValue,
                                                                        mobiles: {
                                                                            mobile: mobileValue,
                                                                            is_whatsapp: update?.mobiles?.is_whatsapp,
                                                                            is_call_done: update?.mobiles?.is_call_done,
                                                                            time: new Date()
                                                                        }
                                                                    });
                                                                    setNewRow({ ...newRow, mobile: mobileValue });
                                                                    setMobileModel(false)
                                                                }}
                                                            >
                                                                Add
                                                            </LoadingButton>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        />
                                        {/* add notes */}
                                        <ConfirmDialog
                                            className="onlineModal"
                                            maxWidth={'sm'}
                                            open={noteModal}
                                            onClose={() => {
                                                setNoteModal(false)
                                            }}
                                            content={
                                                <>
                                                    <CardHeader style={{ padding: "15px 0px", }}
                                                        action={
                                                            <Tooltip title="Close">
                                                                <IconButton color='error' className='CloseBtn' onClick={() => {
                                                                    setNoteModal(false)
                                                                }}><Iconify icon="mdi:cancel-circle-outline" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        title={"Add Notes"}
                                                    />

                                                    <div>
                                                        <TextField multiline fullWidth label="Note" placeholder='Type  here..' value={note} onChange={(e, value) => {
                                                            setNoteModalValue(e.target.value)
                                                            setNote(value)
                                                        }} />
                                                        <div style={{ textAlign: "center" }}>
                                                            <LoadingButton
                                                                color="inherit"
                                                                size="large"
                                                                type="submit"
                                                                loadingPosition="center"
                                                                variant="contained"
                                                                sx={{
                                                                    mt: 5, mb: 3, width: "30%",
                                                                    bgcolor: 'text.primary', typography: 'body1',
                                                                    color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                                                    '&:hover': {
                                                                        bgcolor: 'text.primary',
                                                                        color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    onStatus({ notes: noteModalValue });
                                                                    setNewRow({ ...newRow, notes: noteModalValue });
                                                                    setNoteModal(false)
                                                                }}
                                                            >
                                                                Add
                                                            </LoadingButton>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        />
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );

}

