import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { CustomAvatar } from '../../../components/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  let profile = user?.profiles?.find(p => { return p.type === "profile" });


  return (
    <Link to={PATH_DASHBOARD.user.account} component={RouterLink} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar src={`https://jainshadimilan.com/${profile?.folder}/${profile?.name}`} alt={user?.name?.english} name={user?.name?.english} />
        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name?.english}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user?.roles === 'master' ? 'Super Admin' : user?.roles}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
