import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDeviceDataById } from "src/service";

const LoginDevices = ({ type, item }) => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getDeviceHistoryById();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

    return `${day}/${month}/${year}, ${formattedHours}:${minutes}${ampm}`;
  }

  const getDeviceHistoryById = async () => {
    const res = await getDeviceDataById(item?.id, {
      roles: type === "staff" ? type : null,
    });
    setList(res?.data?.data?.LoginDeviceMaintain);
  };

  console.log("list ::::", list);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Index</TableCell>
              <TableCell>Modal Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Login Date & time</TableCell>
              <TableCell>Last active Date & time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item?.login_device_detail}</TableCell>
                <TableCell>{!item?.log_out_status ? <p className="p-1 rounded-2 text-center bg-success text-white">Logged In</p> : <p className="p-1 rounded-2 text-center bg-danger text-white">Logged Out</p>} </TableCell>
                <TableCell>{formatDate(item?.created_at)}</TableCell>
                <TableCell>{formatDate(item?.updated_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LoginDevices;
