import PropTypes from "prop-types";
// @mui
import {
  Stack,
  Switch,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  Box,
  Grid,
  IconButton,
  CardHeader,
  Avatar,
  Tooltip,
  Checkbox,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Label from "../../../../components/label";
// components
import { useForm, Controller } from "react-hook-form";
import Iconify from "../../../../components/iconify";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import { useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  getAllHinduSectsApi,
  subSectApi,
  staffNameApi,
  assignStaffNameApi,
} from "src/service";
import { useAuthContext } from "src/auth/useAuthContext";
// ----------------------------------------------------------------------

HinduUserTableToolbar.propTypes = {
  isFiltered: PropTypes.bool,
  filterName: PropTypes.string,
  filterRole: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterRole: PropTypes.func,
  onResetFilter: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
};

export default function HinduUserTableToolbar({
  isFiltered,
  filterName,
  filterRole,
  filter,
  setFilter,
  optionsRole,
  onFilterName,
  onFilterRole,
  onResetFilter,
  totalUsers,
  filterDetail,
  staffNumber,
  handleFilterApply,
}) {
  const [open, setOpen] = useState(false);
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");
  const contxt = useAuthContext();
  const [newFilter, setNewFilter] = useState({});
  const handleClose = () => {
    setOpen(false);
  };
  const [subsect, setSubsect] = useState([]);
  const [show, setShow] = useState(false);
  const [staffName, setstaffName] = useState([]);
  const [assignStaffName, setAssignStaffName] = useState([]);
  const [callStaffName, setCallStaffName] = useState([]);
  const [staffActive, setStaffActive] = useState([]);
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [selectedDateTime2, setSelectedDateTime2] = useState();
  const [Sect, setSect] = useState([]);

  useEffect(() => {
    setFilter({ ...filter, startDate: selectedDateTime });
  }, [selectedDateTime]);

  useEffect(() => {
    setFilter({ ...filter, endDate: selectedDateTime2 });
  }, [selectedDateTime2]);

  const ageArray = [];
  for (let index = 14; index < 81; index++) {
    ageArray.push({ label: index + " yrs", value: index });
  }
  const users = [
    { label: "all", value: "all" },
    { label: "Test Users", value: "test-users" },
    { label: "Real Users", value: "real-users" },
  ];
  const days = [
    { label: "all", value: "all" },
    { label: "today", value: "today" },
    { label: "yesterday", value: "yesterday" },
    { label: "Before Yesterday", value: "beforeYesterday" },
    { label: "Last 3 Days", value: "lastThreeDays" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const approveArry = [
    { label: "all", value: "all" },
    { label: "Approved", value: "approved" },
    { label: "Not Approved", value: "not-approved" },
    { label: "View", value: "live" },
    { label: "Not View", value: "not-live" },
  ];
  const gender = [
    { label: "all", value: "all" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];
  const name_search = [
    { label: "all", value: "all" },
    { label: "With Name", value: "with name" },
    { label: "Without Name", value: "without name" },
  ];

  const maritalStatus = [
    { label: "all", value: "all" },
    { label: "Unmarried", value: "Unmarried" },
    { label: "Widow/widower", value: "Widow/Widower" },
    { label: "Divorced", value: "Divorced" },
    { label: "Awaiting divorce", value: "Awaiting divorce" },
  ];
  const ratings = [
    { label: "all", value: "all" },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
  ];
  const call = [
    { label: "all", value: "all" },
    { label: "call", value: "for_call" },
    { label: "no call", value: "no_call" },
    { label: "Not-Connected", value: "not_connected" },
    { label: "Not-Activity", value: "no_activity" },
  ];
  const app_id = [
    { label: "App_id", value: "not_null" },
    { label: "Null", value: "null" },
  ];
  const payment = [
    { label: "all", value: "all" },
    { label: "Payment", value: "done" },
    { label: "No Payment", value: "not_done" },
  ];
  const dosh = [
    { label: "all", value: "all" },
    { label: "YES", value: "YES" },
    { label: "NO", value: "NO" },
    { label: "Ansik Mangal", value: "Anshik" },
    { label: "DON'T KNOW", value: "DON`T KNOW" },
  ];
  const dataAssign = [
    { label: "Assigned Data", value: "assign" },
    { label: "Not Assigned Data", value: "not_assign" },
  ];
  const country = [
    { label: "India", value: "India" },
    { label: "Non-Indian", value: "null" },
  ];

  const methods = useForm({});

  useEffect(() => {
    getstaffName();
    getAssignStaffName("");
    // DiffrentUser();
    getSect();
  }, []);

  const { watch, getValues } = methods;

  const selectSubSect = async (sub) => {
    let res = await subSectApi(sub);
    if (res?.data?.status) {
      setSubsect(res?.data?.data);
    }
  };

  const getSect = async () => {
    const res = await getAllHinduSectsApi();
    let arr = [];
    res?.data?.data?.result?.forEach((sect) => {
      arr.push({ label: sect?.name_english, value: sect?.name_english });
    });
    setSect(arr);
  };

  const getstaffName = async () => {
    var res = await staffNameApi();
    const staffNamesArray = res?.data?.data || [];
    const newStaffObject = {
      mobile: null,
      id: "",
      nameEnglish: "No Staff",
      nameHindi: null,
    };
    staffNamesArray.unshift(newStaffObject);
    setstaffName(staffNamesArray);
  };

  const getAssignStaffName = async () => {
    var res = await assignStaffNameApi();
    setAssignStaffName(res?.data?.data);
    setCallStaffName(res?.data?.data);
    setStaffActive(res?.data?.data);
  };

  return (
    <>
      {filterDetail && (
        <>
          <div>
            <Stack
              style={{
                position: "fixed",
                width: "-webkit-fill-available",
                top: 60,
                background: "#fff",
                zIndex: 999999,
                padding: "14px 10px 10px 10px",
              }}
              spacing={2}
              alignItems="center"
              direction={{
                xs: "column",
                sm: "row",
              }}
              sx={{ px: 0, pb: 1, mt: -1 }}
            >
              {contxt?.user?.sections?.OnlineFilter["Start I'd & end I'd."] && (
                <Grid item lg={3} xs={6} md={3} sm={6}>
                  <TextField
                    value={startId}
                    label="Start Id (आरंभ आईडी)"
                    onChange={(e) => {
                      setStartId(e.target.value);
                      setFilter({ ...filter, startId: e.target.value });
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              )}

              {contxt?.user?.sections?.OnlineFilter["Start I'd & end I'd."] && (
                <Grid item lg={3} xs={6} md={3} sm={6}>
                  <TextField
                    value={endId}
                    label="End Id (अंत आईडी)"
                    onChange={(e) => {
                      setEndId(e.target.value);
                      setFilter({ ...filter, endId: e.target.value });
                    }}
                    sx={{ width: "100%" }}
                  />
                </Grid>
              )}

              {contxt?.user?.sections?.OnlineFilter["Search type"] && (
                <TextField
                  fullWidth
                  select
                  label="Type (तरह)"
                  value={filterRole}
                  onChange={onFilterRole}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxHeight: 260,
                        },
                      },
                    },
                  }}
                  sx={{
                    maxWidth: { sm: 240 },
                    textTransform: "capitalize",
                  }}
                >
                  {optionsRole.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              {contxt?.user?.sections?.OnlineFilter["Search"] && (
                <div style={{ position: "relative", width: "50%" }}>
                  <TextField
                    fullWidth
                    value={filterName}
                    onChange={onFilterName}
                    placeholder="Search... (खोजें)"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon="eva:search-fill"
                            sx={{ color: "text.disabled" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <Label
                    sx={{ position: "absolute", right: 10, top: 14 }}
                    onClick={onResetFilter}
                  >
                    {totalUsers}
                  </Label>
                </div>
              )}
              {isFiltered && (
                <Button
                  color="error"
                  sx={{ flexShrink: 0 }}
                  onClick={onResetFilter}
                  startIcon={<Iconify icon="mdi:cancel-circle-outline" />}
                >
                  Clear
                </Button>
              )}
            </Stack>
          </div>
          <div style={{ padding: "10px 15px 5px 15px" }}>
            <Stack
              spacing={2}
              alignItems="center"
              direction={{
                xs: "column",
                sm: "row",
              }}
              sx={{ px: 0 }}
            >
              <Grid container spacing={2}>
                {contxt?.user?.sections?.OnlineFilter["User entry"] && (
                  <Grid item lg={2} xs={6} md={3} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="created_at"
                      label="Users Entry (यूजर एंट्री)"
                      onChange={(e) => {
                        setFilter({ ...filter, created_at: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {days.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter["Payment"] && (
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="payment_verified_date"
                      label="Payment (भुगतान)"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          payment_verified_date: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {days.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter["Payment expired"] && (
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="payment_expired_date"
                      label="Payment Expired (भुगतान समाप्त)"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          payment_expired_date: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {[
                        ...days,
                        { label: "All Expire", value: "allExpire" },
                      ].map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter[
                  "Payment expired date"
                ] && (
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      label="Payment Expired Start Date (भुगतान समाप्त होने की तिथि)"
                      type="date"
                      fullWidth
                      size={"medium"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={selectedDateTime}
                      onChange={(e) => setSelectedDateTime(e.target.value)}
                      name="date"
                    />
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter[
                  "Payment expired date"
                ] && (
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      label="Payment Expired End Date (भुगतान समाप्त होने की तिथि)"
                      type="date"
                      fullWidth
                      size={"medium"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={selectedDateTime2}
                      onChange={(e) => setSelectedDateTime2(e.target.value)}
                      name="date"
                    />
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter["Approved"] && (
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="verified_at_date"
                      label="Approved (स्वीकृत)"
                      onChange={(e) => {
                        setFilter({
                          ...filter,
                          verified_at_date: e.target.value,
                        });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {days.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter["Staff"] && (
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="staff_id"
                      label="Staff (कर्मचारी)"
                      onChange={(e) => {
                        setFilter({ ...filter, staff_id: e.target.value });
                        getstaffName(e.target.value);
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {staffActive.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.id}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {/* {option?.nameEnglish} */}
                          <ListItemText
                            primary={
                              staffActive?.find((i) => i.id === option.id)
                                ?.public_view === 1
                                ? `${option?.nameEnglish} - Active`
                                : `${option?.nameEnglish} - Inactive`
                            }
                          />
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {contxt?.user?.sections?.OnlineFilter["Task assigned to"] && (
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="assign-staff-label">
                        Task Assign to (कार्य कर्मचारी को सौंपा गया)
                      </InputLabel>
                      <Select
                        labelId="assign-staff-label"
                        multiple
                        value={filter.assign_staff_id || []}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          setFilter({
                            ...filter,
                            assign_staff_id: selectedValues,
                          });
                          getAssignStaffName(selectedValues, true);
                        }}
                        renderValue={(selected) => {
                          return selected
                            .map(
                              (selectedId) =>
                                assignStaffName.find(
                                  (staff) => staff.id === selectedId
                                )?.nameEnglish
                            )
                            .join(", ");
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 260,
                            },
                          },
                        }}
                        sx={{
                          maxWidth: { sm: 410 },
                          textTransform: "capitalize",
                        }}
                      >
                        {assignStaffName.map((option) => (
                          <MenuItem
                            key={option?.value}
                            value={option?.id}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              textTransform: "capitalize",
                            }}
                          >
                            <Checkbox
                              checked={filter.assign_staff_id?.includes(
                                option.id
                              )}
                            />
                            <ListItemText
                              primary={
                                assignStaffName?.find((i) => i.id === option.id)
                                  ?.public_view === 1
                                  ? `${option?.nameEnglish} - Active`
                                  : `${option?.nameEnglish} - Inactive`
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter["Call by staff"] && (
                  <Grid item lg={3} md={3} sm={3} xs={6}>
                    <FormControl fullWidth>
                      <InputLabel id="call-staff-label">
                        Call By Staff (कर्मचारी द्वारा कॉल)
                      </InputLabel>
                      <Select
                        labelId="call-staff-label"
                        multiple
                        value={filter.call_by_staff_id || []}
                        onChange={(e) => {
                          const selectedValues = e.target.value;
                          setFilter({
                            ...filter,
                            call_by_staff_id: selectedValues,
                          });
                          getAssignStaffName(selectedValues, true);
                        }}
                        renderValue={(selected) => {
                          return Array.isArray(selected)
                            ? selected
                                .map(
                                  (selectedId) =>
                                    callStaffName.find(
                                      (staff) => staff.id === selectedId
                                    )?.nameEnglish
                                )
                                .join(", ")
                            : "";
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 260,
                            },
                          },
                        }}
                        sx={{
                          maxWidth: { sm: 410 },
                          textTransform: "capitalize",
                        }}
                      >
                        {callStaffName.map((option) => (
                          <MenuItem
                            key={option?.value}
                            value={option?.id}
                            sx={{
                              mx: 1,
                              my: 0.5,
                              borderRadius: 0.75,
                              textTransform: "capitalize",
                            }}
                          >
                            <Checkbox
                              checked={filter.call_by_staff_id?.includes(
                                option.id
                              )}
                            />
                            <ListItemText
                              primary={
                                callStaffName?.find((i) => i.id === option.id)
                                  ?.public_view === 1
                                  ? `${option?.nameEnglish} - Active`
                                  : `${option?.nameEnglish} - Inactive`
                              }
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                {contxt?.user?.sections?.OnlineFilter["View"] && (
                  <Grid item lg={2} xs={6} md={2} sm={6}>
                    <TextField
                      native
                      fullWidth
                      select
                      name="live_date"
                      label="View (देखें)"
                      onChange={(e) => {
                        setFilter({ ...filter, live_date: e.target.value });
                      }}
                      SelectProps={{
                        MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                      }}
                      sx={{
                        maxWidth: { sm: 410 },
                        textTransform: "capitalize",
                      }}
                    >
                      {days.map((option) => (
                        <MenuItem
                          key={option?.value}
                          value={option?.value}
                          sx={{
                            mx: 1,
                            my: 0.5,
                            borderRadius: 0.75,
                            typography: "body2",
                            textTransform: "capitalize",
                            "&:first-of-type": { mt: 0 },
                            "&:last-of-type": { mb: 0 },
                          }}
                        >
                          {option?.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}

                <Grid
                  item
                  lg={2}
                  xs={6}
                  md={3}
                  sm={6}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"end"}
                >
                  <Box>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        setShow(!show);
                      }}
                      sx={{ typography: "body1", py: 2, mt: 1 }}
                    >
                      {show ? "Hide Advance Filter" : "Show Advance Filter"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Stack>
            {show && (
              <div>
                <Stack
                  spacing={2}
                  alignItems="center"
                  direction={{
                    xs: "column",
                    sm: "row",
                  }}
                  sx={{ px: 0, py: 1 }}
                >
                  <Grid container spacing={2}>
                    {contxt?.user?.sections?.OnlineFilter[
                      "Start I'd & end I'd."
                    ] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="startAge"
                          label="Start Age (आयु शुरू)"
                          onChange={(e) => {
                            setFilter({ ...filter, startAge: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {ageArray?.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter[
                      "Start I'd & end I'd."
                    ] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="endAge"
                          label="End Age (आयु समाप्त)"
                          onChange={(e) => {
                            setFilter({ ...filter, endAge: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {ageArray?.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Gender"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="gender"
                          label="Select Gender (लिंग चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, gender: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {gender.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Sect"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="sect"
                          label="Select Sect (सम्प्रदाय चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, sect: e.target.value });
                            selectSubSect(e.target.value);
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {Sect?.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.label}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Sub sect"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="subSect"
                          label="Sub-Sect (उप-सम्प्रदाय चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, subSect: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {[{ english: "all", english: "all" }]
                            .concat(subsect)
                            ?.map((option) => (
                              <MenuItem
                                key={option?.english}
                                value={option?.english}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {option?.english}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Rating"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="ratings"
                          label="Ratings (रेटिंग)"
                          onChange={(e) => {
                            setFilter({ ...filter, ratings: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {ratings?.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.label}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                  </Grid>
                </Stack>

                <Stack
                  spacing={2}
                  alignItems="center"
                  direction={{
                    xs: "column",
                    sm: "row",
                  }}
                  sx={{ px: 0 }}
                >
                  <Grid container spacing={2}>
                    {contxt?.user?.sections?.OnlineFilter["Education"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="education"
                          label="Select Education (शिक्षा चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, education: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {[{ name_english: "all", value: "all" }]
                            .concat(filterDetail?.education)
                            ?.map((option) => (
                              <MenuItem
                                key={option?.value}
                                value={option?.name_english}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {option?.name_english}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Employee in"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="employedIn"
                          label="Select Profession (पेशा चुनें)"
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              employedIn: e.target.value,
                            });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {[{ label: "all", value: "all" }]
                            .concat(filterDetail?.employedIn?.english)
                            ?.map((option) => (
                              <MenuItem
                                key={option?.value}
                                value={option?.value}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {option?.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Marital status"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="maritalStatus"
                          label="Marital Status (वैवाहिक स्थिति)"
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              maritalStatus: e.target.value,
                            });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {maritalStatus?.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.label}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["State"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="state"
                          label="Select State (राज्य चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, state: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {[{ english: "all", value: "all" }]
                            .concat(filterDetail?.stateList)
                            ?.map((option) => (
                              <MenuItem
                                key={option?.value}
                                value={option?.english}
                                sx={{
                                  mx: 1,
                                  my: 0.5,
                                  borderRadius: 0.75,
                                  typography: "body2",
                                  textTransform: "capitalize",
                                  "&:first-of-type": { mt: 0 },
                                  "&:last-of-type": { mb: 0 },
                                }}
                              >
                                {option?.english}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter[
                      "Approval and not live"
                    ] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="approved"
                          label="Approved and Live (अनुमोदित और सक्रिय)"
                          onChange={(e) => {
                            setFilter({ ...filter, approved: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {approveArry.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Test user"] && (
                      <Grid item lg={2} xs={6} md={3} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="created_at"
                          label="Test Users (परीक्षण उपयोगकर्ता)"
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              test_users: e.target.value,
                            });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {users.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                  </Grid>
                </Stack>

                <Stack
                  spacing={2}
                  alignItems="center"
                  direction={{
                    xs: "column",
                    sm: "row",
                  }}
                  sx={{ px: 0, py: 1 }}
                >
                  <Grid container spacing={2}>
                    {contxt?.user?.sections?.OnlineFilter["Select name"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="name_search"
                          label="Select Name (नाम चुनें)"
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              name_search: e.target.value,
                            });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {name_search.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Select call"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="call"
                          label="Select Call (कॉल चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, call: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {call.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["App I'd"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="app_id"
                          label="App Id (ऐप आईडी)"
                          onChange={(e) => {
                            setFilter({ ...filter, app_id: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {app_id.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Select payment"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="payment"
                          label="Select Payment (भुगतान चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, payment: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {payment.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Country"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="country"
                          label="Country (देश चुनें)"
                          onChange={(e) => {
                            setFilter({ ...filter, country: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {country.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Manglik"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="dosh"
                          label="Manglik (मांगलिक)"
                          onChange={(e) => {
                            setFilter({ ...filter, dosh: e.target.value });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {dosh.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter["Data assign"] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="dataAssign"
                          label="Data Assign (डेटा सौंपना)"
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              assign_data: e.target.value,
                            });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {dataAssign.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}

                    {contxt?.user?.sections?.OnlineFilter[
                      "Search by mobile"
                    ] && (
                      <Grid item lg={2} xs={6} md={2} sm={6}>
                        <TextField
                          native
                          fullWidth
                          select
                          name="searchMobile"
                          label="Search By Mobile (मोबाइल द्वारा खोजें)"
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              calling_number: e.target.value,
                            });
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: { sx: { maxHeight: 260 } },
                            },
                          }}
                          sx={{
                            maxWidth: { sm: 410 },
                            textTransform: "capitalize",
                          }}
                        >
                          {staffNumber.map((option) => (
                            <MenuItem
                              key={option?.value}
                              value={option?.value}
                              sx={{
                                mx: 1,
                                my: 0.5,
                                borderRadius: 0.75,
                                typography: "body2",
                                textTransform: "capitalize",
                                "&:first-of-type": { mt: 0 },
                                "&:last-of-type": { mb: 0 },
                              }}
                            >
                              {option?.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </div>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={handleFilterApply}
                sx={{ typography: "body1", py: 2, mt: 1 }}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
}
