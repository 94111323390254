import { useSnackbar } from "notistack";
import { useRef } from "react";

const useCopyImageWithId = (imageId) => {
  const canvasRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const copyImageWithId = async (imageUrl) => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const image = new Image();
    image.crossOrigin = "Anonymous";
    image.src = imageUrl;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      context.drawImage(image, 0, 0);

      const fontSize = 32;
      context.font = `${fontSize}px Arial`;
      context.textAlign = "left";
      context.textBaseline = "top";

      const text = `#${imageId}`;
      const textWidth = context.measureText(text).width;
      const textHeight = fontSize;

      const padding = 5;
      const backgroundColor = "white";
      const textColor = "#ed1c24";
      const borderRadius = 5;

      context.fillStyle = backgroundColor;
      context.beginPath();
      context.moveTo(10 + borderRadius, 10);
      context.lineTo(10 + textWidth + padding * 2 - borderRadius, 10);
      context.quadraticCurveTo(
        10 + textWidth + padding * 2,
        10,
        10 + textWidth + padding * 2,
        10 + borderRadius
      );
      context.lineTo(
        10 + textWidth + padding * 2,
        10 + textHeight + padding * 2 - borderRadius
      );
      context.quadraticCurveTo(
        10 + textWidth + padding * 2,
        10 + textHeight + padding * 2,
        10 + textWidth + padding * 2 - borderRadius,
        10 + textHeight + padding * 2
      );
      context.lineTo(10 + borderRadius, 10 + textHeight + padding * 2);
      context.quadraticCurveTo(
        10,
        10 + textHeight + padding * 2,
        10,
        10 + textHeight + padding * 2 - borderRadius
      );
      context.lineTo(10, 10 + borderRadius);
      context.quadraticCurveTo(10, 10, 10 + borderRadius, 10);
      context.closePath();
      context.fill();

      context.fillStyle = textColor;
      context.fillText(text, 10 + padding, 10 + padding);

      canvas.toBlob(async (blob) => {
        if (blob) {
          try {
            const item = new ClipboardItem({ "image/png": blob });
            await navigator.clipboard.write([item]);
            enqueueSnackbar("Image copied to clipboard!");
          } catch (err) {
            console.error("Failed to copy image:", err);
          }
        }
      }, "image/png");
    };

    image.onerror = () => {
      console.error("Failed to load the image.");
    };
  };

  return { copyImageWithId, canvasRef };
};

export default useCopyImageWithId;
