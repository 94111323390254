import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Modal, Paper, Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import constant from 'src/Constant';
import { useAuthContext } from 'src/auth/useAuthContext';
import ConfirmDialog from 'src/components/confirm-dialog/ConfirmDialog';
import { deletePushNotification, SendPushNotification, offPushNotification } from 'src/service';
import PushNotificationEdit from 'src/pages/General/PushNotificationEdit';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';


const buttonStyle = {
    borderRadius: '50%',
    padding: '0',
    lineHeight: '0',
    margin: '0 1px',
    marginLeft: '2px',
    marginRight: '4px',
    minWidth: '20px',
    minHeight: '20px',
};


const CardButtonStyles = {
    backgroundColor: '#c4cdd5',
    color: '#3e43eb',
    marginRight: '0.5em',
    borderRadius: '25px',
    '&:hover': {
        backgroundColor: '#c4cdd5',
    },
};

const titleStyle = {
    fontSize: "12px",

}
export const PushNotificationCard = ({ setPageRefresh, getNotificationList, id, data }) => {
    const [selectedDays, setSelectedDays] = useState([false, false, false, false, false, false, false]);
    const [status, setStatus] = useState(data?.status ? 1 : 0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [startId, setStartId] = useState('');
    const navigate = useNavigate();
    const [endId, setEndId] = useState('');
    const [btnData, setBtnData] = useState([]);
    const { user } = useAuthContext();
    const { enqueueSnackbar } = useSnackbar();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const toggleSelectedDay = (index) => {
        const newSelectedDays = [...selectedDays];
        newSelectedDays[index] = !newSelectedDays[index];
        setSelectedDays(newSelectedDays);
    };

    useEffect(() => {
        setBtnData(JSON.parse(data?.button));
    }, [id])

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleStatusChange = async () => {
        const formData = new FormData();
        formData.append("id", data?.id);
        // status
    }
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleSubmit = async () => {
        let obj = {
            "id": data?.id,
            "startId": startId,
            "endId": endId
        }
        try {
            let res = await SendPushNotification(obj);
            if (res?.data?.message) {
                enqueueSnackbar(res?.data?.message)
                setStatus(true);
                getNotificationList();
                closeModal();
            }
        } catch (error) {
            enqueueSnackbar(error?.error, { variant: 'error' });
            closeModal();
        }
    };

    const onDelete = async () => {
        let obj = {
            id: data?.id
        };
        try {
            let res = await deletePushNotification(obj);

            if (res?.data?.status) {
                enqueueSnackbar(res?.data?.message);
                getNotificationList();
            } else {
                enqueueSnackbar(res?.data?.message, { variant: 'error' });
            }
        } catch (error) {
            console.error("Error deleting user:", error);
            enqueueSnackbar("An error occurred while deleting the user.", { variant: 'error' });
        }
    };

    const toggleSwitch = async () => {
        if (status === 1) {
            try {
                const objid = { id: data?.id };
                const response = await offPushNotification(objid);

                if (response?.data?.data?.message) {
                    enqueueSnackbar(response.data.data.message);
                    // getNotificationList()
                    setStatus(0);
                }
            } catch (error) {
                console.error('API call error:', error);
            }
        } else {
            openModal();
        }
    };

    const handleOpenEditModal = () => {
        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        window.location.reload()
    };

    return (
        <>
            <Card sx={{ border: "1px solid black" }}>
                <CardHeader sx={{ borderBottom: '1px solid black' }} title={
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Stack direction={'column'}>
                                {/* <Typography style={titleStyle} variant='body1' color='inherit'>
                                    For:
                                </Typography> */}
                                <Typography style={titleStyle} variant='body1' color='inherit'>
                                    <Stack alignItems={'center'} direction={'row'}>
                                        <Grid item xs={6}><b>Id:</b> {data?.id ? data?.id : ''}</Grid>
                                        <Grid display={'flex'} sx={{ marginRight: "3em" }} alignItems={'center'} item xs={6}>
                                            <Typography>
                                                Status
                                            </Typography>
                                            <Switch
                                                checked={status}
                                                size="small"
                                                onChange={toggleSwitch}
                                            />
                                        </Grid>
                                    </Stack>
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction={'column'}>
                                <Typography style={titleStyle} variant='body1'>
                                    Schedule : {data?.shedule ? data?.shedule : ''}
                                </Typography>
                                {/* <Box>
                                    {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                                        <Button
                                            sx={buttonStyle}
                                            key={day}
                                            variant='contained'
                                            onClick={() => toggleSelectedDay(index)}
                                            color={selectedDays[index] ? 'primary' : 'inherit'}
                                        >
                                            {day}
                                        </Button>
                                    ))}
                                </Box> */}
                            </Stack>
                        </Grid>
                    </Grid>
                } />
                <CardContent sx={{ backgroundColor: '#e2e2e2' }}>
                    <Card>
                        <CardHeader
                            sx={{ my: 1 }}
                            title={<Stack direction={'column'}>
                                {data?.heading && <Typography variant='body1' sx={{ fontWeight: "600" }}>{data?.heading}</Typography>}
                                {data?.sub_heading && <Typography variant='body1'>{data?.sub_heading}</Typography>}
                            </Stack>
                            } />
                        {data?.image && <CardMedia
                            component="img"
                            sx={{ mx: "auto", width: 330, borderRadius: 1 }}
                            height="194"
                            image={`${constant.appBaseUrl}/users/push_notification/${data?.image}`}
                            alt=""
                        />}
                        <CardActions>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'} display={'flex'} justifyContent={'end'}>
                                        {btnData?.map((btn, index) => {
                                            return (
                                                <Button key={index} variant='contained' href={`${btn?.textFieldLabelLink}`} sx={CardButtonStyles} >{btn?.textFieldLabelName}</Button>
                                            )
                                        })}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                </CardContent>
                <CardActions sx={{ backgroundColor: '#e2e2e2' }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Stack direction={'row'} sx={{ marginBottom: 2 }} display={'flex'} justifyContent={'center'}>
                                <Button color='primary' size="small" onClick={handleOpenConfirm} sx={{ mx: '0.5em' }} variant='contained'>Delete</Button>
                                <Button color='inherit' onClick={handleOpenEditModal} size="small" sx={{ mx: '0.5em', background: '#ffffff' }} variant='contained'>Edit</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card >
            <Modal open={openEditModal} onClose={handleCloseEditModal}>
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "90%", maxHeight: "80vh", bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4, overflowY: 'auto' }}>
                    <PushNotificationEdit id={data?.id} data={data} getNotificationList={getNotificationList} handleCloseEditModal={handleCloseEditModal} />
                </Box>
            </Modal>
            <Modal open={isModalOpen} onClose={closeModal}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '30vw',
                        height: "30vh",
                        bgcolor: 'background.paper',
                        p: 4,
                        borderRadius: '10px',
                    }}
                >
                    <TextField
                        label="Start ID"
                        variant="outlined"
                        fullWidth
                        value={startId}
                        onChange={(e) => setStartId(e.target.value)}
                    />
                    <TextField
                        label="End ID"
                        variant="outlined"
                        fullWidth
                        value={endId}
                        onChange={(e) => setEndId(e.target.value)}
                    />
                    <Button onClick={closeModal} sx={{ mx: 2, my: 4 }} variant="contained" color="error">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} sx={{ mx: 2, my: 4 }} variant="contained" color="primary">
                        Submit
                    </Button>
                </Box>
            </Modal>
            <ConfirmDialog className="deleteModal"
                open={openConfirm}
                onClose={handleCloseConfirm}
                title="Delete"
                content="Are you sure want to delete?"
                action={
                    <div>
                        <Button variant="outlined" color="inherit" onClick={handleCloseConfirm} style={{ marginRight: 10 }}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="error" onClick={() => {
                            onDelete();
                            handleCloseConfirm();
                        }}>
                            Delete
                        </Button>
                    </div>
                }
            />
        </>
    )
}


