import React from 'react'
import { getCountAnalyticDataType, getCountAnalyticJainType, getCountAnalyticSpecialType, getCountAnalyticStateType } from 'src/service';
import { useEffect, useState } from "react";
const AnalyticsHistory = () => {
    const [analyticsDataType, setAnalyticsDataType] = useState([]);
    const [analyticsCommunityType, setAnalyticsCommunityType] = useState([]);
    const [analyticsStateType, setAnalyticsStateType] = useState([]);
    const [analyticsSpecialType, setAnalyticsSpecialType] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const startId = '860875';
                // const endId = '860885';
                const result = await getCountAnalyticDataType();
                if (result.status && Array.isArray(result.data)) {
                    setAnalyticsDataType(result.data);
                }
            } catch (error) {
                console.error('Error fetching analytics data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const communityTypeData = async () => {
            try {
                // const startId = '860875';
                // const endId = '860885';
                const result = await getCountAnalyticJainType();
                if (result.status && Array.isArray(result.data)) {
                    setAnalyticsCommunityType(result.data);
                }
            } catch (error) {
                console.error('Error fetching analytics data:', error);
            }
        };

        communityTypeData();
    }, []);

    useEffect(() => {
        const stateTypeData = async () => {
            try {
                // const startId = '860875';
                // const endId = '860885';
                const result = await getCountAnalyticStateType();
                if (result.status && Array.isArray(result.data)) {
                    setAnalyticsStateType(result.data);
                }
            } catch (error) {
                console.error('Error fetching analytics data:', error);
            }
        };

        stateTypeData();
    }, []);
    useEffect(() => {
        const specialTypeData = async () => {
            try {
                // const startId = '860875';
                // const endId = '860885';
                const result = await getCountAnalyticSpecialType();
                if (result.status && Array.isArray(result.data)) {
                    setAnalyticsSpecialType(result.data);
                }
            } catch (error) {
                console.error('Error fetching analytics data:', error);
            }
        };

        specialTypeData();
    }, []);
    return (
        <div>
            <div style={{ color: 'white', backgroundColor: 'red', marginBottom: '5px' }}>Analytics History</div>

            <div style={{ width: '100%' }}>
                <p style={{ color: 'white', backgroundColor: 'blue', width: '100%', padding: '2px', textAlign: 'center' }}>Data Type</p>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                    {analyticsDataType.map((item, index) => (
                        <div key={item.key} style={{
                            width: 'calc(20% - 20px)',
                            minWidth: '100px',
                            height: '100px',
                            backgroundColor: 'lightpink',
                            border: '10px solid lightpink',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                            fontWeight: 'bold',
                            margin: '10px'
                        }}>
                            <div>{item.value}</div>
                            <div>{item.name}</div>
                        </div>
                    ))}
                </div>

                <p style={{ color: 'white', backgroundColor: 'blue', width: '100%', padding: '2px', textAlign: 'center' }}>Sect</p>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                    {analyticsCommunityType.map((item, index) => (
                        <div key={item.key} style={{
                            width: 'calc(20% - 20px)',
                            minWidth: '100px',
                            height: '100px',
                            backgroundColor: 'lightblue',
                            border: '10px solid lightblue',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                            fontWeight: 'bold',
                            margin: '10px'
                        }}>
                            <div>{item.value}</div>
                            <div>{item.name}</div>
                        </div>
                    ))}
                </div>
                <p style={{ color: 'white', backgroundColor: 'blue', width: '100%', padding: '2px', textAlign: 'center' }}>State</p>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                    {analyticsStateType.map((item, index) => (
                        <div key={item.key} style={{
                            width: 'calc(20% - 20px)',
                            minWidth: '100px',
                            height: '100px',
                            backgroundColor: '#E1C16E',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                            fontWeight: 'bold',
                            margin: '10px'
                        }}>
                            <div>{item.value}</div>
                            <div>{item.name}</div>
                        </div>
                    ))}
                </div>
                {/* special case  */}
                <p style={{ color: 'white', backgroundColor: 'blue', width: '100%', padding: '2px', textAlign: 'center' }}>Special Case</p>
                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px' }}>
                    {analyticsSpecialType.map((item, index) => (
                        <div key={item.key} style={{
                            width: 'calc(20% - 20px)',
                            minWidth: '100px',
                            height: '100px',
                            backgroundColor: 'lightgreen',
                            borderRadius: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                            fontWeight: 'bold',
                            margin: '10px'
                        }}>
                            <div>{item.value}</div>
                            <div>{item.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default AnalyticsHistory