import { useCallback } from "react";

const useExtractDetails = () => {
  const stateRegex =
    /\b(Arunachal Pradesh|Assam|Bihar|Chhattisgarh|Goa|Gujarat|Haryana|Himachal Pradesh|Jharkhand|Karnataka|Kerala|Madhya Pradesh|Maharashtra|Manipur|Meghalaya|Mizoram|Nagaland|Odisha|Punjab|Rajasthan|Sikkim|Tamil Nadu|Telangana|Tripura|Uttar Pradesh|Uttarakhand|West Bengal|Andhra Pradesh|Andaman and Nicobar Islands|Chandigarh|Dadra and Nagar Haveli and Daman and Diu|Delhi|Ladakh|Lakshadweep|Puducherry)\b/i;
  const numberRegex =
    /(\+91[\-\s]?)?(\(?[789]\d{2}\)?[\-\s]?\d{3}[\-\s]?\d{4})/g;

  const namePatterns = [
    // Match 'Name' without 'Father', 'Mother', 'Grandfather', etc. preceding it
    /(?:^|\n)\s*Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+\s+[A-Z][a-zA-Z'-]+)(?:\s|$)/i,

    // Match 'Full Name' with two words
    /(?:^|\n)\s*Full\s*Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+\s+[A-Z][a-zA-Z'-]+)(?:\s|$)/i,

    // Variations for 'First Name' and 'Last Name' together
    /(?:^|\n)\s*First\s*Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)\s+Last\s*Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)(?:\s|$)/i,

    // Variations for 'Given Name' and 'Surname' together
    /(?:^|\n)\s*Given\s*Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)\s+Surname\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)(?:\s|$)/i,

    // Variations for 'First name' and 'Family name' together
    /(?:^|\n)\s*First\s*name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)\s+Family\s*name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)(?:\s|$)/i,

    // Match 'Initial Name' or common abbreviations capturing two words
    /(?:^|\n)\s*Initial\s*Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+\s+[A-Z][a-zA-Z'-]+)(?:\s|$)/i,
    /(?:^|\n)\s*F\.?Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)\s+L\.?Name\s*[:\-]?\s*([A-Z][a-zA-Z'-]+)(?:\s|$)/i,

    // Basic 'Name' pattern, restricting from matching 'Father Name', etc.
    /(?:^|\n)\s*Name\s*[:=\-]?\s*([A-Z][a-zA-Z'-]+\s+[A-Z][a-zA-Z'-]+)(?:\s|$)/i,
    /Full\s*Name\s*[:=\-]?\s*([A-Z][a-zA-Z'-]+\s+[A-Z][a-zA-Z'-]+)/i,
  ];

  const dobPatterns = [
    // Variations for 'Date of Birth' with numeric dates
    /Date\s*of\s*Birth\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{4})/i,
    /Date\s*of\s*Birth\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{2})/i,

    // Variations for 'DOB' with numeric dates
    /DOB\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{4})/i,
    /DOB\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{2})/i,

    // Variations for 'D.O.B.' with numeric dates
    /D\.O\.B\.\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{4})/i,
    /D\.O\.B\.\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{2})/i,

    // Variations for 'Date of Birth' with day, month name, and year
    /Date\s*of\s*Birth\s*[:\-]?\s*(\d{1,2}[^A-Za-z0-9]*[A-Za-z]+\s+\d{4})/i,

    // Variations for 'Birth Date' with numeric dates
    /Birth\s*Date\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{4})/i,
    /Birth\s*Date\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{2})/i,

    // Variations for 'Birth of Date' with numeric dates
    /Birth\s*of\s*Date\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{4})/i,
    /Birth\s*of\s*Date\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{2})/i,

    /Birth\s*Date\s*[:=]?\s*(\d{1,2}\/\d{1,2}\/\d{4})/i,

    // Variations for 'DateofBith' (common misspelling) with numeric dates
    /DateofBith\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{4})/i,
    /DateofBith\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{2})/i,

    // Variations for 'Birth of date' (different casing and spacing) with numeric dates
    /Birth\s*of\s*date\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{4})/i,
    /Birth\s*of\s*date\s*[:\-]?\s*(\d{2}[\/\-\.\s]\d{2}[\/\-\.\s]\d{2})/i,

    // Variations for date with month name and ordinal (like '28th Dec 1995')
    /Date\s*of\s*Birth\s*[:\-]?\s*(\d{1,2}(?:st|nd|rd|th)?\s+[A-Za-z]+\s+\d{4})/i,
    /DOB\s*[:\-]?\s*(\d{1,2}(?:st|nd|rd|th)?\s+[A-Za-z]+\s+\d{4})/i,
    /D\.O\.B\.\s*[:\-]?\s*(\d{1,2}(?:st|nd|rd|th)?\s+[A-Za-z]+\s+\d{4})/i,
    /Birth\s*Date\s*[:\-]?\s*(\d{1,2}(?:st|nd|rd|th)?\s+[A-Za-z]+\s+\d{4})/i,
    /Birth\s*of\s*Date\s*[:\-]?\s*(\d{1,2}(?:st|nd|rd|th)?\s+[A-Za-z]+\s+\d{4})/i,
    /DateofBith\s*[:\-]?\s*(\d{1,2}(?:st|nd|rd|th)?\s+[A-Za-z]+\s+\d{4})/i,
    /Birth\s*of\s*date\s*[:\-]?\s*(\d{1,2}(?:st|nd|rd|th)?\s+[A-Za-z]+\s+\d{4})/i,

    /(?:Date\s*of\s*Birth\s*[:+\-]?\s*)(\d{1,2}[\/\-\s]\d{1,2}[\/\-\s]\d{2,4}|\d{1,2}\s+[A-Za-z]+\s+\d{4})/i,
    /(?:Birthdate|Date\s*of\s*Birth)\s*[:=~-]*\s*(\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}|\d{1,2}\s+[A-Za-z]+\s+\d{4})/i,
    /(?:Date\s*Of\s*Birth|Birthdate)\s*[©:=~-]*\s*(\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}|\d{1,2}[\/\-]\s?[A-Za-z]+\s?[\/\-]?\s?\d{4}|\d{1,2}\s+[A-Za-z]+\s+\d{4})/i,
    /Birthdate\s*[:=\-]?\s*(\d{1,2}[\/\-]?\s?[A-Za-z]*\s?[\/\-]?\d{2,4}|\d{4})/i,
    /Date\s*of\s*Birth\s*[:=\-]?\s*(\d{1,2}[^\w\s]?\s?[A-Za-z]+\s?\d{4})/i,
    /Date\s*of\s*Birth\s*[:=\-]?\s*(\d{1,2}[^\w\s]?\s?[A-Za-z]+\s?[^\w\s]?\s?\d{4})/i,
  ];

  const ageRegex = /Age\s*:\s*(\d+)/;

  const extractDetails = useCallback((text) => {
    let matchedState = null;
    let matchedNumbers = null;
    let matchedAllNumbers = [];
    let matchedName = null;
    let matchedDob = null;
    let matchedAge = null;

    // Match state
    if (stateRegex.test(text)) {
      matchedState = text.match(stateRegex)[0];
    }

    // Match numbers
    if (numberRegex.test(text)) {
      matchedNumbers = text.match(numberRegex)[0];
    }
    matchedAllNumbers = text.match(numberRegex) || [];

    // Remove duplicates and format numbers
    const uniqueNumbers = [
      ...new Set(
        matchedAllNumbers
          .map((num) => num?.replace(/[^0-9]/g, "") || null)
          .filter(Boolean)
      ),
    ];

    const formattedNumbers = uniqueNumbers.map((number) => ({
      mobile: number,
      is_whatsapp: false,
      is_call_done: false,
      time: null,
    }));

    if (formattedNumbers && formattedNumbers.length > 0) {
      matchedAllNumbers = formattedNumbers;
    } else {
      matchedAllNumbers = null;
    }

    // Match age
    matchedAge = text.match(ageRegex) || null;

    // Match name
    namePatterns.forEach((pattern) => {
      const matches = text.match(pattern);
      if (matches) {
        if (matches.length > 2) {
          matchedName = `${matches[1]} ${matches[2]}`;
        } else {
          matchedName = matches[1];
        }
      }
    });

    // Match date of birth
    for (const pattern of dobPatterns) {
      const match = text.match(pattern);
      if (match) {
        matchedDob = match[1];
        break;
      }
    }

    // Extract year from dob
    let dobYear = null;
    if (matchedDob) {
      const dobParts = matchedDob.split(/[\/\-\.\s]/);
      dobYear = parseInt(dobParts[2], 10);
    }

    return {
      state: matchedState,
      mobiles: matchedAllNumbers,
      mobile: matchedNumbers,
      name: matchedName,
      dob: matchedDob,
      age: matchedAge ? parseInt(matchedAge[1], 10) : null,
      dobYear,
    };
  }, []);

  return { extractDetails };
};

export default useExtractDetails;
