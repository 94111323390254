import PropTypes from 'prop-types';
import { useState } from 'react';

// @mui
import { alpha, styled } from '@mui/material/styles';
import { Box, Card, Avatar, Divider, Grid, Tooltip, Button, Typography, Stack, IconButton } from '@mui/material';
// utils
// _mock
import { _socials } from '../../../../_mock/arrays';
// components
import Image from '../../../../components/image';
import Switch from '@mui/material/Switch';
import MenuPopover from '../../../../components/menu-popover';
import ConfirmDialog from '../../../../components/confirm-dialog';
import Iconify from '../../../../components/iconify';
import { useSnackbar } from '../../../../components/snackbar';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';



// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
    top: 0,
    left: 0,
    zIndex: 8,
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

// ----------------------------------------------------------------------

const label = { inputProps: { 'aria-label': 'Switch demo' } };


HinduCasteEntriesCard.propTypes = {
    img: PropTypes.object,
    selected: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSelect: PropTypes.func,
};



export default function HinduCasteEntriesCard({ img, row, selected, onEdit, onSelect, onDelete }) {

    const { id, first_form, path, second_form, cover, mobile, status, following } = img;

    const [openConfirm, setOpenConfirm] = useState(false);
    const [openPopover, setOpenPopover] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { copy } = useCopyToClipboard();

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };
    const handleOpenPopover = (event) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
      };

      const handleCopymobile = () => {
        enqueueSnackbar('Copied!');
        copy(mobile);
      };

    return (
        <Grid sx={{ mb: 15 }} >

            <Card key={img.value} sx={{ textAlign: 'center' }}>

                <Box variant="subtitle1" display="flex" justifyContent="center" sx={{ flexDirection: 'row' }}>

                    <Box variant="subtitle1" display="flex" sx={{ flexDirection: 'row' }}>
                    <Typography
                        variant="h6"
                        sx={{ mb: 1, mt: 3, ml: 3, display: 'block' }}
                    >
                        Name 
                    </Typography>

                    <Typography
                        variant="h6"
                        sx={{ mb: 1, mt: 3, ml: 2, display: 'block', color: 'text.secondary' }}
                    >

                    </Typography>
                  </Box>

                  <Box variant="subtitle1" display="flex" sx={{ flexDirection: 'row' }}>
                    <Typography variant="subtitle2" noWrap>
                          {/* {mobile && <Iconify icon="material-symbols:content-copy-outline" width="18" height="18" onClick={() => {
                           handleClosePopover();
                           handleCopymobile();
                           }} />} */}
                                {/* {mobile} */}
                     </Typography>

                  </Box>
                </Box>
                <Divider sx={{ borderStyle: 'solid' }} />

                <Box display="flex" alignItems="center" justifyContent="space-between">
                    

                   

                </Box>


                {/* <box open={openPopover} onClose={handleClosePopover} arrow="right-top">

                    <Tooltip title="Delete" onClick={() => {
                        handleOpenConfirm();
                        handleClosePopover();
                    }} sx={{ position: "relative", right: "10px", mb: 1.5, typography: 'body1' }}>
                        <Button variant="contained">
                            Delete
                        </Button>
                    </Tooltip>

                    <Tooltip title="Update" onClick={() => {
                        onEdit();
                        handleClosePopover();
                    }} sx={{ position: "relative", left: "10px", mb: 1.5, typography: 'body1' }}>
                        <Button variant="contained" color="inherit" >
                            Edit
                        </Button>
                    </Tooltip>

                </box> */}

                <ConfirmDialog className="deleteModal"
                    open={openConfirm}
                    onClose={handleCloseConfirm}
                    title="Delete"
                    content="Are you sure want to delete?"
                    action={
                        <div>
                            <Button variant="outlined" color="inherit" onClick={handleCloseConfirm} style={{ marginRight: 10 }}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="error" onClick={onDelete}>
                                Delete
                            </Button>
                        </div>
                    }
                />
            </Card >
        </Grid>

    );
}
