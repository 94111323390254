import PropTypes from "prop-types";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config";
// components
import Logo from "../../../components/logo";
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import { PATH_DASHBOARD } from "src/routes/paths";
import LanguagePopover from "./LanguagePopover";
import ContactsPopover from "./ContactsPopover";
import NotificationsPopover from "./NotificationsPopover";
import { useState, useEffect, useCallback } from "react";
import ImportData from "src/pages/General/GlobalSearch";
import { useLocation, useNavigate } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import constant from "../../../Constant";
import GlobalSearch from "./GlobalSearch";
import {
  addScannerDataApi,
  addScannerHinduDataApi,
  bioDataImageGetApi,
  deleteScannerImageStatusApi,
  deleteScannerImageStatusHinduApi,
  extractInfoFromGpt,
  getBiodataListApi,
  getGroupServiceListApi,
  getIncomingServiceListApi,
  getPaidPromotionListApi,
  getPendingOfflineUsersList,
  // getPendingOnlineUsersList,
  getPendingOnlineUsersListFiltered,
  getScannerImageHinduNameApi,
  getScannerImageNameApi,
  getSocialMediaListApi,
  getWhatsappResponseListApi,
  removeBiodata,
  shareTaskCount,
  startScannerApi,
  updateImagesDataScanner,
  updateImageStatusApi,
  updateScannerImageStatusApi,
  updateScannerImageStatusHinduApi,
  updateUploadedBiodata,
} from "src/service";
import { useAuthContext } from "src/auth/useAuthContext";
import { useSnackbar } from "notistack";
import Tesseract from "tesseract.js";
import useExtractDetails from "src/hooks/useExtractDetails ";
// import { getGlobalSearch } from 'src/service';
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();
  const { themeLayout } = useSettingsContext();
  const isNavHorizontal = themeLayout === "horizontal";
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const isNavMini = themeLayout === "mini";
  const isDesktop = useResponsive("up", "lg");
  const [showGlobalSearch, setShowGlobalSearch] = useState(false);
  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;
  const [open, setOpen] = useState(false);
  const [call, setCall] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [shareCardCount, setShareCardCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { extractDetails } = useExtractDetails();
  // console.log('setShareCardCount ::::', shareCardCount)
  const [data, setData] = useState([]);

  const handleClose = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (
      window.location.pathname === "/onlineuser" ||
      window.location.pathname === "/shareTask"
    ) {
      shareCount();
    }
  }, []);

  useEffect(() => {
    setCall(!call);
  }, [window.location]);

  const handleUpdateBiodata = async () => {
    const res = await updateImagesDataScanner();
    if (res?.data?.status) {
      let imgData = res?.data?.data?.map((img) => {
        return {
          name: null,
          mobile: img?.mobile,
          state: img?.states,
          image: img?.image_address,
        };
      });
      const dataUploadResponse = await addScannerDataApi(imgData);
      if (dataUploadResponse?.data?.status) {
        enqueueSnackbar(dataUploadResponse?.data?.message);
      }
    }
  };

  const getPendingCount = useCallback(async () => {
    try {
      const promises = [
        getPendingOfflineUsersList(0, 5),
        getPendingOnlineUsersListFiltered(0, 25, { task: "registration" }),
        getIncomingServiceListApi(0, 5),
        getWhatsappResponseListApi(0, 5),
        getSocialMediaListApi(0, 5),
        getPaidPromotionListApi(0, 5),
        getGroupServiceListApi(0, 5),
        getBiodataListApi(0, 5),
      ];

      const responses = await Promise.all(promises);

      let count = responses.reduce((total, res) => {
        return total + Number(res?.data?.data?.total || 0);
      }, 0);

      setPendingCount(count);
    } catch (error) {
      console.error("Error while fetching pending counts:", error);
    }
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/onlineuser" ||
      location.pathname === "/pendingTask"
    ) {
      getPendingCount();
    }
  }, [location.pathname, call, getPendingCount]);

  const shareCount = async () => {
    const res = await shareTaskCount();
    if (res?.data?.status) {
      setShareCardCount(res?.data?.data);
    }
  };

  const updateWithGpt = async () => {
    const data = [
      {
        id: 1,
        description: `|
       पशवंतराव पांगारकर रमेश रौकडे
      9403084686 9423679308

      जन्म दिनांक : २०/१०/१११६

      जन्म aes : सकानी ७.१०

      जन्म ठिकाण : अंधेरी, मुंबई,

      उंची : ५.३

      स्क्त गट : Bt

      जात : तिव्ठवण तेली

      शिक्षण : बी.कॉम

      नौकरी : Landmark Insurance Brokers Pvt Ltd -
      Ghatkopar, Mumbai -86.

      BET: Sr.Account Executive

      मासिक उत्पन्न : 32,000/-

      पालकांचा व्यवसाय : नोकरी

      पत्ता : ओमकार नगर, नेतिवली, कल्याण पूर्व, जि.ठाणे,
      गाव : dftorts, ता.मौताब्छा, जि:बुलढाणा,

      मामाचे नाव : राजेंद्र सोमनाथ चौधरी

      माम कुव्ठ : ठाकरे

      अपेक्षा ; चांगली नौकरी, मुंबईत किंवा पुण्यात राहणारा
      संपर्क ; १६११५३८१९३

      ¥ HERS राज्य Ach sey वर

      Bas HSH Got
      ane निवृत्ती घोंगते. श्री मनोजकुमार चौधरी हू

      wr प्रदेश अध्यक्ष प्रदेश उपाध्यक्ष
      दी 9967208380 7588735463
      लग्न जमावतांना दिलेल्या माहितीची शहानिशा करून घेणे
      संस्थाजबाबदार रहाणार नाही याची Ale घ्यावी
       `,
      },
      {
        id: 2,
        description: `page सुज्य तेल तेली

नदिफाताई मखामाले प्रितम Fert पशवतराव पागारकर रमेश wee
8850883529 9225518383 9403084686 9423679308

ast. सोयगाव
मामांचे कुछ : हिंगे

है. ser eres ach aj ae

De Gach HSS Gor
‘Gitar निवृत्ती घोंगते. .... श्री मनोजकुमार चौथरी

Ve प्रदेश अध्यक्ष प्रदेश उपाध्यक्ष
9967208380 7588735463

लग्न जमावतांना दिलेल्या माहितीची शहानिशा करून घेणे
संस्थाजबाबदार रहाणार नाही याची Ale घ्यावी
 `,
      },
    ];
    const finalData = [];
    for (let i = 0; i < data.length; i++) {
      try {
        const res = await extractInfoFromGpt(data[i].description);
        console.log("res ::::", res);
        finalData.push(JSON.parse(res.data.choices[0].message.content));
      } catch (error) {
        console.log("error ::::", error);
      }
    }
    console.log("finalData ::::", finalData);
  };

  const runScannerFunction = async (count = 0) => {
    setIsLoading(true);

    if (1) {
      const res = await getScannerImageNameApi();
      const images = res?.data?.data?.images;

      for (let i = 0; i < images?.length; i++) {
        const image = {};
        const imageResponse = await fetch(
          `${constant.appBaseUrl}/users/offline_users/${images[i]?.image_address}`
        );

        if (!imageResponse.ok) {
          console.warn("Image fetch failed with status", imageResponse.status);
          continue;
        }

        const imageBlob = await imageResponse.blob();

        try {
          const {
            data: { text },
          } = await Tesseract.recognize(imageBlob, "eng");

          const { state, mobiles, mobile, name, dob, age, dobYear } =
            extractDetails(text);

          image.description = text;
          image.state = state;
          image.mobile = mobile;
          image.upload_by = images[i]?.upload_by;
          image.mobiles = mobiles;
          image.name = name;
          image.status = 1;
          image.dobYear = dobYear;
          image.uploaded_date = new Date();
          image.image = images[i]?.image_address;
          image.upload_type = "bulk";

          await addScannerDataApi([image]);
          await updateScannerImageStatusApi(images[i]?.id);
        } catch (ocrError) {
          console.error("OCR Error:", ocrError);
          setIsLoading(false);
          await deleteScannerImageStatusApi(images[i]?.id);
        }
      }
      enqueueSnackbar(`${images?.length} scanned completed`);
      setIsLoading(false);
      if (count < 160) {
        await runScannerFunction(count + 1);
      }
    } else {
      try {
        const res = await startScannerApi();
        if (res?.data?.success) {
          enqueueSnackbar(res?.data?.message);
        }
        setIsLoading(false);
        if (count < 49) {
          await runScannerFunction(count + 1);
        }
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const runScannerBiodataUploadedFunction = async (count = 0) => {
    setIsLoading(true);

    let res = await bioDataImageGetApi(0, 25, {
      filter: "upload_images",
    });
    const images = res?.data?.data?.images;

    for (let i = 0; i < images?.length; i++) {
      const image = {};
      const imageResponse = await fetch(
        `${constant.appBaseUrl}/users/offline_users/${images[i]?.image}`
      );

      if (!imageResponse.ok) {
        console.warn("Image fetch failed with status", imageResponse.status);
        continue;
      }

      const imageBlob = await imageResponse.blob();

      try {
        const {
          data: { text },
        } = await Tesseract.recognize(imageBlob, "eng");

        const { state, mobiles, mobile, name, dob, age, dobYear } =
          extractDetails(text);

        image.description = text;
        image.state = state;
        image.mobile = mobile;
        image.upload_by = images[i]?.upload_by;
        image.mobiles = mobiles;
        image.name = name;
        image.status = 1;
        image.dobYear = dobYear;
        image.uploaded_date = new Date();
        image.image = images[i]?.image;
        image.upload_type = "biodata uploading";

        await addScannerDataApi([image]);
        await updateImageStatusApi(images[i]?.id);
      } catch (ocrError) {
        console.error("OCR Error:", ocrError);
        setIsLoading(false);
        await removeBiodata({ id: images[i]?.id });
      }
    }
    enqueueSnackbar(`${images?.length} scanned completed`);
    setIsLoading(false);
    if (count < 160) {
      await runScannerBiodataUploadedFunction(count + 1);
    }
  };

  const runHinduScannerFunction = async (count = 0) => {
    setIsLoading(true);

    if (1) {
      const res = await getScannerImageHinduNameApi();
      const images = res?.data?.data?.images;

      for (let i = 0; i < images?.length; i++) {
        const image = {};
        const imageResponse = await fetch(
          `${constant.appBaseUrl}/users/offline_users/${images[i]?.image_address}`
        );

        if (!imageResponse.ok) {
          console.warn("Image fetch failed with status", imageResponse.status);
          continue;
        }

        const imageBlob = await imageResponse.blob();

        try {
          const {
            data: { text },
          } = await Tesseract.recognize(imageBlob, "eng");

          const { state, mobiles, mobile, name, dob, age, dobYear } =
            extractDetails(text);

          image.description = text;
          image.state = state;
          image.mobile = mobile;
          image.upload_by = images[i]?.upload_by;
          image.mobiles = mobiles;
          image.name = name;
          image.status = 1;
          image.dobYear = dobYear;
          image.uploaded_date = new Date();
          image.image = images[i]?.image_address;
          await addScannerHinduDataApi([image]);
          await updateScannerImageStatusHinduApi(images[i]?.id);
        } catch (ocrError) {
          console.error("OCR Error:", ocrError);
          setIsLoading(false);
          await deleteScannerImageStatusHinduApi(images[i]?.id);
        }
      }
      enqueueSnackbar(`${images?.length} scanned completed`);
      setIsLoading(false);
      if (count < 160) {
        await runHinduScannerFunction(count + 1);
      }
    } else {
      try {
        const res = await startScannerApi();
        if (res?.data?.success) {
          enqueueSnackbar(res?.data?.message);
        }
        setIsLoading(false);
        if (count < 49) {
          await runScannerFunction(count + 1);
        }
      } catch (error) {
        enqueueSnackbar(error?.message, { variant: "error" });
        setIsLoading(false);
      }
    }
  };

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        {/* <LanguagePopover /> */}

        {/* <div style={{ position: "relative", width: "50%" }}>
          <TextField
            onChange={(e) => setnumber(e.target.value)}
            fullWidth
            placeholder="Global Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
            value={mobile} // Bind the input value to the state
            onChange={(event) => setMobile(event.target.value)}
          >
          </TextField>
          <Label
            sx={{ position: "absolute", right: 10, top: 14 }}
            onClick={onResetFilter}
          >{totalUsers}
          </Label>
        </div> */}

        {/* <Button
          variant="contained"
          onClick={() => navigate(PATH_DASHBOARD.general.userRequest)}
        >
          User's Request (0)
        </Button> */}
        {(window.location.pathname === "/onlineuser" ||
          window.location.pathname === "/shareTask") && (
          <Button
            variant="contained"
            sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
            onClick={() => {
              if (window.location.pathname === "/shareTask") {
                navigate(PATH_DASHBOARD.general.onlineuser);
              } else {
                navigate(PATH_DASHBOARD.general.shareTask);
              }
            }}
          >
            Shared Task ({shareCardCount})
          </Button>
        )}

        {/*<Button variant="contained" onClick={updateWithGpt}>
          Gpt Click
        </Button>*/}

        {(window.location.pathname === "/onlineuser" ||
          window.location.pathname === "/pendingTask") && (
          <Button
            variant="contained"
            sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
            onClick={() => {
              if (window.location.pathname === "/pendingTask") {
                navigate(PATH_DASHBOARD.general.onlineuser);
              } else {
                navigate(PATH_DASHBOARD.general.pendingTask);
              }
            }}
          >
            Pending Task ({pendingCount})
          </Button>
        )}

        {constant?.updateBiodata && (
          <Button variant="contained" onClick={handleUpdateBiodata}>
            Update Biodata
          </Button>
        )}

        {/* {1 && ( */}
        {/* {user?.id === 53083 && (
          <Button
            variant="contained"
            onClick={() => runScannerBiodataUploadedFunction()}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Jain Run Biodata"
            )}
          </Button>
        )}
        {user?.id === 53083 && (
          <Button
            variant="contained"
            onClick={() => runScannerFunction()}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Jain Run Scanner"
            )}
          </Button>
        )} */}
        {/* {1 && ( */}
        {/* {user?.id === 53083 && (
          <Button
            variant="contained"
            onClick={() => runHinduScannerFunction()}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Hindu Run Scanner"
            )}
          </Button>
        )} */}

        {/* <button onClick={search}>
          Search
        </button> */}
        {window.location.pathname !== "/incominguseradd" &&
          window.location.pathname !== "/incominguser" && (
            <Button
              className="mx-1"
              variant="contained"
              onClick={() => navigate(PATH_DASHBOARD?.general?.incominguseradd)}
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Incoming
            </Button>
          )}

        {/* {contxt?.user?.sections['Global Search']['Master Admin Access'] && <GlobalSearch />} */}
        <Button
          variant="contained"
          sx={{ fontSize: { md: "0.8rem", sm: "0.6rem", xs: "0.6rem" } }}
          onClick={() => {
            navigate("/newGlobalSearch");
          }}
        >
          Global Search
        </Button>

        {/* <GlobalSearch /> */}
        <NotificationsPopover />
        <AccountPopover />

        {/* <ContactsPopover /> */}
      </Stack>
      <ImportData open={open} data={data} handleClose={handleClose} />
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar>{renderContent}</Toolbar>
    </AppBar>
  );
}
