import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Grid } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const DropdownDatePicker = ({
  onChange,
  defaultDate = null,
  isTimer = false,
  hasYear = false,
  hasHour = false,
}) => {
  const [date, setDate] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [hourOffset, setHourOffset] = useState(0);

  useEffect(() => {
    const currentDate = defaultDate ? new Date(defaultDate) : new Date();

    setYear(currentDate.getFullYear());
    setMonth(currentDate.getMonth() + 1);
    setDate(currentDate.getDate());

    if (hourOffset > 0) {
      const adjustedTime = new Date(currentDate);
      adjustedTime.setHours(adjustedTime.getHours() + hourOffset);
      updateDate(
        adjustedTime.getDate(),
        adjustedTime.getMonth() + 1,
        adjustedTime.getFullYear(),
        hourOffset
      );
    }
  }, [defaultDate, hourOffset]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setDate(newDate);
    updateDate(newDate, month, year, hourOffset);
  };

  const handleMonthChange = (event) => {
    const newMonth = event.target.value;
    setMonth(newMonth);
    updateDate(date, newMonth, year, hourOffset);
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setYear(newYear);
    updateDate(date, month, newYear, hourOffset);
  };

  const handleHourOffsetChange = (event) => {
    const selectedHourOffset = event.target.value;
    setHourOffset(selectedHourOffset);
    updateDate(date, month, year, selectedHourOffset);
  };

  const updateDate = (day, month, year, hoursOffset = 0) => {
    const currentDate = new Date();
    const adjustedDate = new Date(
      year || currentDate.getFullYear(),
      month - 1,
      day || currentDate.getDate(),
      currentDate.getHours() + hoursOffset,
      currentDate.getMinutes()
    );
    const isoString = adjustedDate.toISOString();
    onChange(isoString);
  };

  const generateOptions = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => i + start).map(
      (value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      )
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Month</InputLabel>
            <Select value={month} onChange={handleMonthChange} label="Month">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, index) => (
                <MenuItem key={index} value={month}>
                  {String(month).padStart(2, "0")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Day</InputLabel>
            <Select value={date} onChange={handleDateChange} label="Date">
              {generateOptions(0, 31)}
            </Select>
          </FormControl>
        </Grid>
        {hasYear && (
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Year</InputLabel>
              <Select value={year} onChange={handleYearChange} label="Year">
                {generateOptions(1900, 2100)}
              </Select>
            </FormControl>
          </Grid>
        )}
        {hasHour && (
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Hours from Now</InputLabel>
              <Select
                value={hourOffset}
                onChange={handleHourOffsetChange}
                label="Hours from Now"
              >
                {generateOptions(0, 12)}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </LocalizationProvider>
  );
};

export default DropdownDatePicker;

export const TimeOffsetPicker = ({ onDateChange }) => {
  const [selectedMonths, setSelectedMonths] = useState(0);
  const [selectedDays, setSelectedDays] = useState(0);
  const [selectedHours, setSelectedHours] = useState(0);

  const handleMonthsChange = (event) => {
    const newMonths = event.target.value;
    setSelectedMonths(newMonths);
    updateDate(newMonths, selectedDays, selectedHours);
  };

  const handleDaysChange = (event) => {
    const newDays = event.target.value;
    setSelectedDays(newDays);
    updateDate(selectedMonths, newDays, selectedHours);
  };

  const handleHoursChange = (event) => {
    const newHours = event.target.value;
    setSelectedHours(newHours);
    updateDate(selectedMonths, selectedDays, newHours);
  };

  const updateDate = (months, days, hours) => {
    const currentDate = new Date();

    // Use the current values from the date to avoid overwriting them.
    const updatedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );

    // Apply the offsets
    updatedDate.setMonth(updatedDate.getMonth() + parseInt(months, 10));
    updatedDate.setDate(updatedDate.getDate() + parseInt(days, 10));
    updatedDate.setHours(updatedDate.getHours() + parseInt(hours, 10));

    const isoString = updatedDate.toISOString();
    onDateChange(isoString);
  };

  const generateOptions = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => i + start).map(
      (value) => (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      )
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>Months from Now</InputLabel>
          <Select
            value={selectedMonths}
            onChange={handleMonthsChange}
            label="Months"
          >
            {generateOptions(0, 12)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>Days from Now</InputLabel>
          <Select value={selectedDays} onChange={handleDaysChange} label="Days">
            {generateOptions(0, 31)}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel>Hours from Now</InputLabel>
          <Select
            value={selectedHours}
            onChange={handleHoursChange}
            label="Hours"
          >
            {generateOptions(0, 23)}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
