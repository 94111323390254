// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const contxt = JSON.parse(localStorage.getItem("sections"));

const ICONS = {
  blog: icon("ic_blog"),
  cart: icon("ic_cart"),
  chat: icon("ic_chat"),
  mail: icon("ic_mail"),
  user: icon("ic_user"),
  file: icon("ic_file"),
  lock: icon("ic_lock"),
  label: icon("ic_label"),
  blank: icon("ic_blank"),
  kanban: icon("ic_kanban"),
  folder: icon("ic_folder"),
  banking: icon("ic_banking"),
  booking: icon("ic_booking"),
  invoice: icon("ic_invoice"),
  calendar: icon("ic_calendar"),
  disabled: icon("ic_disabled"),
  external: icon("ic_external"),
  menuItem: icon("ic_menu_item"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig = [
  {
    subheader: "general",
    items: [
      {
        title: "app",
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
    ],
  },
];
if (contxt?.sections?.onlineUsers?.read)
  navConfig[0].items.push({
    title: "Online User",
    path: PATH_DASHBOARD.general.onlineuser,
    icon: ICONS.user,
  });
if (contxt?.sections?.onlineUsers?.read)
  navConfig[0].items.push({
    title: "Hindu Online User",
    path: PATH_DASHBOARD.general.hinduonlineuser,
    icon: ICONS.user,
  });
if (contxt?.sections?.DeletedUsers?.read)
  navConfig[0].items.push({
    title: "Suspended User",
    path: PATH_DASHBOARD.general.deleteduser,
    icon: ICONS.user,
  });
if (contxt?.sections?.offlineUsers?.read)
  navConfig[0].items.push({
    title: "Offline Users",
    path: PATH_DASHBOARD.general.offlineUsers,
    icon: ICONS.user,
  });
// if (contxt?.sections?.offlineUsers?.read) navConfig[0].items.push({ title: 'Group Link', path: PATH_DASHBOARD.general.grouplinks, icon: ICONS.user })
if (contxt?.sections?.bioDataUsers?.read)
  navConfig[0].items.push({
    title: "Bio Data Users",
    path: PATH_DASHBOARD.general.biodatausers,
    icon: ICONS.user,
  });
if (contxt?.sections?.hinduBioData?.read)
  navConfig[0].items.push({
    title: "Hindu Bio Data",
    path: PATH_DASHBOARD.general.hindubiodata,
    icon: ICONS.user,
  });
if (contxt?.sections?.staff?.read)
  navConfig[0].items.push({
    title: "My Staff",
    path: PATH_DASHBOARD.general.mystaff,
    icon: ICONS.user,
  });
if (contxt?.sections?.padadhikari?.read)
  navConfig[0].items.push({
    title: "Padadhikari",
    path: PATH_DASHBOARD.general.padadhikari,
    icon: ICONS.user,
  });
if (contxt?.sections?.mobiles?.read)
  navConfig[0].items.push({
    title: "Mobiles",
    path: PATH_DASHBOARD.general.mobiles,
    icon: ICONS.user,
  });
if (contxt?.sections?.packages?.read)
  navConfig[0].items.push({
    title: "My Packages",
    path: PATH_DASHBOARD.general.mypackages,
    icon: ICONS.user,
  });
if (contxt?.sections?.notes?.read)
  navConfig[0].items.push({
    title: "Notes",
    path: PATH_DASHBOARD.general.notes,
    icon: ICONS.user,
  });
if (contxt?.sections?.JainForms?.read)
  navConfig[0].items.push({
    title: "Forms",
    path: PATH_DASHBOARD.general.forms,
    icon: ICONS.user,
  });
if (contxt?.sections?.hinduForm?.read)
  navConfig[0].items.push({
    title: "Hindu Forms",
    path: PATH_DASHBOARD.general.hinduforms,
    icon: ICONS.user,
  });
if (contxt?.sections?.hinduImagesUpload?.read)
  navConfig[0].items.push({
    title: "Hindu BioData Downloading",
    path: PATH_DASHBOARD.general.hindubiodatadawnloading,
    icon: ICONS.user,
  });
if (contxt?.sections?.hinduImagesUpdate?.read)
  navConfig[0].items.push({
    title: "Hindu BioData Downloading update",
    path: PATH_DASHBOARD.general.hindubiodatadawnloadingupdate,
    icon: ICONS.user,
  });
if (contxt?.sections?.fieldwork?.read)
  navConfig[0].items.push({
    title: "Field Work",
    path: PATH_DASHBOARD.general.fieldwork,
    icon: ICONS.user,
  });
if (contxt?.sections?.whatsappMessage?.read)
  navConfig[0].items.push({
    title: "Message",
    path: PATH_DASHBOARD.general.message,
    icon: ICONS.user,
  });
if (contxt?.sections["Response Data"]?.read)
  navConfig[0].items.push({
    title: "Response Data",
    path: PATH_DASHBOARD.general.responsedata,
    icon: ICONS.user,
  });
// if (contxt?.sections?.whatsappResponse?.read)
//   navConfig[0].items.push({
//     title: "Whatsapp Response",
//     path: PATH_DASHBOARD.general.whatsappresponse,
//     icon: ICONS.user,
//   });
if (contxt?.sections?.hinduwhatsappresponse?.read)
  navConfig[0].items.push({
    title: "Hindu Whatsapp Response",
    path: PATH_DASHBOARD.general.hinduwhatsappresponse,
    icon: ICONS.user,
  });
if (contxt?.sections?.staffReport?.read)
  navConfig[0].items.push({
    title: "Staff Report",
    path: PATH_DASHBOARD.general.staffreport,
    icon: ICONS.user,
  });
if (contxt?.sections?.banner?.read)
  navConfig[0].items.push({
    title: "Banner",
    path: PATH_DASHBOARD.general.bannerpage,
    icon: ICONS.user,
  });
if (contxt?.sections?.banner?.read)
  navConfig[0].items.push({
    title: "Excel History",
    path: PATH_DASHBOARD.general.excelhistory,
    icon: ICONS.user,
  });
if (contxt?.sections?.banner?.read)
  navConfig[0].items.push({
    title: "Push Notification",
    path: PATH_DASHBOARD.general.pushNotification,
    icon: ICONS.user,
  });
if (contxt?.sections?.Setting?.read)
  navConfig[0].items.push({
    title: "Settings",
    path: PATH_DASHBOARD.general.settings,
    icon: ICONS.user,
  });

navConfig[0].items = [
  ...navConfig[0].items,
  ...[
    // { title: 'Online User', path: PATH_DASHBOARD.general.onlineuser, icon: ICONS.user },
    // { title: 'Deleted User', path: PATH_DASHBOARD.general.deleteduser, icon: ICONS.user },
    // { title: 'Banner', path: PATH_DASHBOARD.general.bannerpage, icon: ICONS.user },
    // { title: 'My Packages', path: PATH_DASHBOARD.general.mypackages, icon: ICONS.user },
    // { title: 'Bio Data Users', path: PATH_DASHBOARD.general.biodatausers, icon: ICONS.user },
    // { title: 'Hindu Bio Data', path: PATH_DASHBOARD.general.hindubiodata, icon: ICONS.user },
    // { title: 'My Staff', path: PATH_DASHBOARD.general.mystaff, icon: ICONS.user },
    // { title: 'Mobiles', path: PATH_DASHBOARD.general.mobiles, icon: ICONS.user },
    // { title: 'Offline Users', path: PATH_DASHBOARD.general.offlineUsers, icon: ICONS.user },
    // { title: 'Padadhikari', path: PATH_DASHBOARD.general.padadhikari, icon: ICONS.user },
    // { title: 'Forms', path: PATH_DASHBOARD.general.forms, icon: ICONS.user },
    // { title: 'Staff Report', path: PATH_DASHBOARD.general.staffreport, icon: ICONS.user },
    // {
    //   title: "Hindu IncomingCall",
    //   path: PATH_DASHBOARD.general.hinduincoming,
    //   icon: ICONS.user,
    // },
    // {
    //   title: "Hindu Response Data",
    //   path: PATH_DASHBOARD.general.hinduresponsedata,
    //   icon: ICONS.user,
    // },
    {
      title: "Incoming Users",
      path: PATH_DASHBOARD.general.incominguser,
      icon: ICONS.user,
    },
    {
      title: "BioData Dawnloading",
      path: PATH_DASHBOARD.general.biodatadawnloadingupdate,
      icon: ICONS.user,
    },
    {
      title: "BioData Dawnloading",
      path: PATH_DASHBOARD.general.biodatauploading,
      icon: ICONS.user,
    },
    {
      title: "Hindu BioData Downloading",
      path: PATH_DASHBOARD.general.hindubiodatauploading,
      icon: ICONS.user,
    },
    {
      title: "Field Approvel",
      path: PATH_DASHBOARD.general.fieldapprovel,
      icon: ICONS.user,
    },
    // { title: 'BioData Dawnloading', path: PATH_DASHBOARD.general.biodatadawnloading, icon: ICONS.user }
    // { title: 'Hindu BioData Dawnloading', path: PATH_DASHBOARD.general.hindubiodatadawnloading, icon: ICONS.user },
    // { title: 'Hindu BioData Dawnloading', path: PATH_DASHBOARD.general.hindubiodatadawnloadingupdate, icon: ICONS.user },
    // { title: 'Hindu Forms', path: PATH_DASHBOARD.general.hinduforms, icon: ICONS.user },
    // { title: 'Whatsapp Response', path: PATH_DASHBOARD.general.whatsappresponse, icon: ICONS.user },
    // { title: 'Message', path: PATH_DASHBOARD.general.message, icon: ICONS.user },
    // { title: 'Notes', path: PATH_DASHBOARD.general.notes, icon: ICONS.user },
    // { title: 'Field Work', path: PATH_DASHBOARD.general.fieldwork, icon: ICONS.user },
    // { title: 'Role', path: PATH_DASHBOARD.general.rolemanagement, icon: ICONS.user },
  ],
];

if (true) {
  navConfig[0].items.push({
    title: "Role",
    path: PATH_DASHBOARD.general.rolemanagement,
    icon: ICONS.user,
  });
  navConfig[0].items.push({
    title: "Scanner Uploading",
    path: PATH_DASHBOARD.general.scannerUpload,
    icon: ICONS.user,
  });
  navConfig[0].items.push({
    title: "Hindu Scanner Uploading",
    path: PATH_DASHBOARD.general.scannerUploadHindu,
    icon: ICONS.user,
  });
}

// console.log("contxt", contxt?.roles === "admin");
if (contxt?.roles === "admin") {
  navConfig[0].items.push({
    title: "Contact Us",
    path: PATH_DASHBOARD.general.contactus,
    icon: ICONS.user,
  });
  navConfig[0].items.push({
    title: "Settings",
    path: PATH_DASHBOARD.general.settings,
    icon: ICONS.user,
  });
}

export default navConfig;
