import React, { useEffect, useRef, useState } from 'react'
import { MenuItem, Select, IconButton, Input, Card, Button, Box, Switch, Typography, Grid, TextField, Stack, CardContent } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Iconify from 'src/components/iconify';
import { Helmet } from 'react-helmet-async';
import { updatePushNotification } from 'src/service'
import constant from 'src/Constant';
import { useAuthContext } from 'src/auth/useAuthContext';
import { RHFUploadAvatar } from 'src/components/hook-form';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { useSnackbar } from 'notistack';



const customStyles = {
    fileUpload: {
        display: 'inline-block',
        padding: '6px 12px',
        cursor: 'pointer',
        border: '1px solid #f9f9f9',
        borderRadius: '50px',
        backgroundColor: '#f9f9f9',
        color: '#333',
    },
    inputFile: {
        display: 'none',
    },
    span: {
        marginRight: '10px',
    },
};

const buttonStyle = {
    borderRadius: '50%',
    margin: '0 1px',
    minWidth: '20px',
    minHeight: '20px',
};

const PushNotificationEdit = ({ getNotificationList, handleCloseEditModal, data }) => {
    const [showImage, setShowImage] = useState(data?.image ? data?.image : false);
    const [showTimer, setShowTimer] = useState(data?.timer ? data?.timer : false);
    const [filePreview, setFilePreview] = useState(null);
    const formattedDateTime = data?.timer ? new Date(data?.timer).toISOString().slice(0, 16) : null;
    const [selectedDateTime, setSelectedDateTime] = useState(formattedDateTime);
    const fileRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuthContext();
    const [selectedOption, setSelectedOption] = useState(data?.shedule);
    const [selectedDays, setSelectedDays] = useState([false, false, false, false, false, false, false]);
    const [showCustomSection, setShowCustomSection] = useState(false);
    const [values, setValues] = useState({
        status: data?.heading ?? '',
        heading: data?.heading ?? '',
        sub_heading: data?.sub_heading ?? '',
        link: data?.link ?? '',
        end_date: data?.end_date ?? '',
    })


    const [sections, setSections] = useState(
        data?.button ? JSON.parse(data?.button) :
            [
                {
                    textFieldLabelName: '',
                    textFieldLabelLink: '',
                },
            ]
    );

    const addSection = () => {
        const newSection = {
            textFieldLabelName: '',
            textFieldLabelLink: '',
        };
        setSections([...sections, newSection]);
    };

    const removeSection = () => {
        if (sections.length > 1) {
            const updatedSections = [...sections];
            updatedSections.pop();
            setSections(updatedSections);
        }
    };

    const handleLabelNameChange = (index, value) => {
        const updatedSections = [...sections];
        updatedSections[index].textFieldLabelName = value;
        setSections(updatedSections);
    };

    const handleLabelLinkChange = (index, value) => {
        const updatedSections = [...sections];
        updatedSections[index].textFieldLabelLink = value;
        setSections(updatedSections);
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        if (name === 'shedule') {
            setSelectedOption(newValue);
            setShowCustomSection(newValue === 'Custom');
        } else if (name === 'custom_days') {
            const updatedSelectedDays = [...selectedDays];
            updatedSelectedDays[parseInt(value)] = !updatedSelectedDays[parseInt(value)];
            setSelectedDays(updatedSelectedDays);
        } else if (name === 'end_date') {
            setSelectedDateTime(value);
        }

        setValues({ ...values, [name]: newValue });
    }
    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setFilePreview(e.target.result);
            };

            reader.readAsDataURL(selectedFile);
        } else {
            setFilePreview(null);
        }
    };

    const toggleSelectedDay = (index) => {
        const newSelectedDays = [...selectedDays];
        newSelectedDays[index] = !newSelectedDays[index];
        setSelectedDays(newSelectedDays);
    };
    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);
        setShowCustomSection(selectedValue === 'Custom');
    };

    const onSubmit = async () => {
        const formData = new FormData();
        formData.append("status", values.status ?? data?.status);
        formData.append("heading", values.heading ?? data?.heading);
        formData.append("sub_heading", values.sub_heading ?? data?.sub_heading);
        formData.append("link", values.link ?? data?.link);
        if (fileRef && fileRef.current && fileRef.current.files.length > 0) {
            formData.append('image', fileRef.current.files[0]);
        }
        formData.append("timer", selectedDateTime ?? data?.timer);
        formData.append("button", JSON.stringify(sections) ?? data?.button);
        formData.append("button_link", null);
        formData.append("shedule", values.shedule ?? data?.shedule);
        formData.append("end_date", values.end_date ?? data?.end_date);
        const res = await updatePushNotification(formData, data?.id);
        enqueueSnackbar(res?.data?.message)

        setValues({
            status: false,
            heading: '',
            sub_heading: '',
            link: '',
            image: '',
            button: '',
            button_link: '',
            shedule: '',
            end_date: '',
        })
        setSelectedDateTime(null)
        setSections([
            {
                textFieldLabelName: '',
                textFieldLabelLink: '',
            },
        ]);
        handleCloseEditModal()
    }
    return (
        <>
            <Box sx={{ display: "flex", justifyContent: "end", marginBottom: "1.5em" }}>
                {/* { && <Button color='inherit' size="small" sx={{ mx: '0.5em', background: '#ffffff' }} variant='contained'>Edit</Button>} */}
                {1 && <Button
                    variant="contained"
                    color='inherit'
                    onClick={handleCloseEditModal}
                >
                    X
                </Button>}
            </Box>
            {1 && <>
                <Typography sx={{ my: 3, fontWeight: "bold" }} variant='h5'>Edit Notification</Typography>
                <Grid container spacing={2}>
                    <Stack direction={'column'}>
                        <Stack sx={{ mt: 3, marginLeft: 2 }} spacing={2} direction={'row'}>
                            <Grid item xs={4}>
                                <TextField
                                    label="Heading"
                                    name='heading'
                                    variant="outlined"
                                    fullWidth
                                    value={values.heading}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Sub heading"
                                    variant="outlined"
                                    fullWidth
                                    name='sub_heading'
                                    value={values.sub_heading}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    label="Link"
                                    variant="outlined"
                                    fullWidth
                                    name='link'
                                    value={values.link}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Stack>
                        <Stack sx={{ mt: 1, marginLeft: 2 }} spacing={2} direction={'row'}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" >
                                    <Typography variant="h5">
                                        Image
                                    </Typography>
                                    <Switch
                                        checked={showImage}
                                        size='medium'
                                        onChange={(e) => setShowImage(e.target.checked)}
                                    />
                                </Box>
                            </Grid>
                        </Stack>
                        {showImage && (
                            <Stack sx={{ mt: 1, marginLeft: 2 }} spacing={2} direction={'row'} alignItems="center">
                                <Grid item xs={6}>
                                    <label htmlFor="file" style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', backgroundColor: "#f7fbfe", padding: "0.5em", width: "50%", borderRadius: 4 }}>
                                        <PermMediaIcon color='success' sx={{ marginRight: 1 }} />
                                        <Typography variant="body1" color="textSecondary">
                                            Choose File
                                        </Typography>
                                        <input
                                            type="file"
                                            id="file"
                                            ref={fileRef}
                                            accept=".png, .jpg, .jpeg"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                        />
                                    </label>
                                </Grid>
                            </Stack>
                        )}
                        {(data?.image || filePreview) && (
                            <Card sx={{ maxWidth: "300px", m: '1em' }}>
                                <CardContent>
                                    <div>
                                        <img
                                            src={data?.image ? `${constant.appBaseUrl}/users/push_notification/${data?.image}` : filePreview}
                                            alt="Selected"
                                            style={{ maxWidth: '200px', height: 'auto' }}
                                        />
                                    </div>
                                </CardContent>
                            </Card>
                        )}
                        <Stack sx={{ mt: 1, marginLeft: 2 }} spacing={2} direction={'row'}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" >
                                    <Typography variant="h5">
                                        Timer
                                    </Typography>
                                    <Switch
                                        checked={showTimer}
                                        size='medium'
                                        onChange={(e) => { setShowTimer(e.target.checked) }}
                                    />
                                </Box>
                            </Grid>
                        </Stack>
                        {showTimer && (
                            <Stack sx={{ mt: 2, marginLeft: 2 }} spacing={2} direction={'row'}>
                                <Grid item xs={8}>
                                    <Box>
                                        <TextField
                                            label="Offer End Date"
                                            type="datetime-local"
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={selectedDateTime || null}
                                            onChange={handleChange}
                                            name="end_date"
                                        />
                                    </Box>
                                </Grid>
                            </Stack>
                        )}
                        {sections.map((section, index) => (
                            <div key={index}>
                                <Stack sx={{ mt: 1, marginLeft: 2 }} spacing={2} direction={'row'}>
                                    <Grid item xs={12}>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="h5">Button {index + 1}</Typography>
                                        </Box>
                                    </Grid>
                                </Stack>
                                <Stack sx={{ mt: 1, marginLeft: 2 }} spacing={2} direction={'row'}>
                                    <Grid item xs={4}>
                                        <TextField
                                            label={`Label Name ${index + 1}`}
                                            variant="outlined"
                                            fullWidth
                                            value={section.textFieldLabelName}
                                            onChange={(e) => handleLabelNameChange(index, e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            label={`Label Link ${index + 1}`}
                                            variant="outlined"
                                            fullWidth
                                            value={section.textFieldLabelLink}
                                            onChange={(e) => handleLabelLinkChange(index, e.target.value)}
                                        />
                                    </Grid>
                                </Stack>
                            </div>
                        ))}
                        <Stack sx={{ mt: 1, marginLeft: 2 }} spacing={2} direction={'row'}>
                            <Grid spacing={2} item xs={12}>
                                <Button
                                    variant="outlined"
                                    color="success"
                                    sx={{ backgroundColor: '#f9f9f9', marginRight: '0.5em' }}
                                    startIcon={<Iconify icon="eva:plus-fill" />}
                                    onClick={addSection}
                                >
                                    Add Button
                                </Button>
                                {sections.length > 1 && (
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ backgroundColor: '#f9f9f9' }}
                                        startIcon={<Iconify icon="eva:minus-fill" />}
                                        onClick={removeSection}
                                    >
                                        Remove Button
                                    </Button>
                                )}
                            </Grid>
                        </Stack>
                        <Stack sx={{ mt: 3, marginLeft: 2 }} spacing={2} direction={'row'}>
                            <Grid item xs={6}>
                                <Typography variant='h5' display={'flex'} alignItems={'center'}>
                                    schedule
                                    <Select
                                        fullWidth
                                        value={selectedOption}
                                        name="shedule"
                                        onChange={handleChange}
                                        style={{ marginLeft: '1rem' }}
                                    >
                                        <MenuItem value="once">Once</MenuItem>
                                        <MenuItem value="daily">Daily</MenuItem>
                                        <MenuItem value="weekend">Weekend</MenuItem>
                                        <MenuItem onClick={() => setShowCustomSection(!showCustomSection)} value="Custom">Custom</MenuItem>
                                    </Select>
                                </Typography>
                            </Grid>
                        </Stack>
                        {showCustomSection &&
                            <Stack direction={'column'}>
                                <Stack sx={{ mt: 3, marginLeft: 2 }} spacing={0.5} direction={'row'}>
                                    <Grid item xs={7}>
                                        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                                            <Button
                                                sx={buttonStyle}
                                                key={day}
                                                variant='contained'
                                                onClick={() => handleChange({ target: { name: 'custom_days', value: index } })}
                                                color={selectedDays[index] ? 'primary' : 'inherit'}
                                            >
                                                {day}
                                            </Button>
                                        ))}
                                    </Grid>
                                </Stack>
                                <Stack sx={{ mt: 2, marginLeft: 2 }} spacing={2} direction={'row'}>
                                    <Grid item xs={6}>
                                        <Box>
                                            <TextField
                                                label="Offer End Date"
                                                type="datetime-local"
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                value={values.end_date || ''}
                                                onChange={(e) => handleChange({ target: { name: 'end_date', value: e.target.value } })}
                                            />
                                        </Box>
                                    </Grid>
                                </Stack>
                            </Stack>
                        }
                        <Stack sx={{ mt: 4, marginLeft: 2 }} spacing={2} direction={'row'}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center" justifyContent={'center'} >
                                    <Button variant='contained' color='primary' onClick={onSubmit} >Update</Button>
                                </Box>
                            </Grid>
                        </Stack>
                    </Stack>
                </Grid>
            </>}
        </>
    )
}

export default PushNotificationEdit
