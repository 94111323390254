import { Helmet } from "react-helmet-async";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Grid,
  Card,
  Button,
  CardContent,
  Typography,
  CardHeader,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
import { useEffect } from "react";
import {
  getDetailList,
  getStaffNumberAPI,
  getStateListAPI,
  DiffrentUserApi,
  SuspendUserApi,
  bioDataDetail,
  moveToHinduFromOfflineUsers,
  moveOfflineToDiffrentLanguage,
  moveOfflineToSuspended,
  getNotes,
  getIncomingCallApi,
  excelDownloadOfflineUser,
} from "src/service";
import { useSnackbar } from "notistack";
import { useAuthContext } from "src/auth/useAuthContext";
import { saveAs } from "file-saver";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { AnalyticsWidgetSummary } from "src/sections/@dashboard/general/analytics";
import IncomingUserFilter from "src/sections/@dashboard/user/list/IncomingUserFilter";
import IncomingCard from "src/sections/@dashboard/user/cards/IncomingCard";
import {
  TableNoData,
  TablePaginationCustom,
  useTable,
} from "src/components/table";
import ImportData from "src/pages/General/ImportData";
// ----------------------------------------------------------------------

export default function IncomingGlobalCard({
  incomingGlobalCallList,
  type,
  show,
}) {
  const user = JSON.parse(localStorage.getItem("sections"));
  const {
    dense,
    page,
    rowsPerPage,
    onSelectAllRows,
    onSelectRow,
    setPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onChangePageButton,
    selected,
  } = useTable();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [filterDetail, setFilterDetail] = useState({});
  const [isNotFound, setIsNotFound] = useState(false);
  const [filterMobile, setFilterMobile] = useState([""]);
  const [filterName, setFilterName] = useState([""]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [filter, setFilter] = useState({});
  const [incomingCallList, setIncomingCallList] = useState([]);
  const [usersDetailDifferent, setOffineUsersDifferent] = useState([]);
  const [showDifferent, setShowDifferent] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [staffNumber, setStaffNumber] = useState([]);
  const [update, setUpdate] = useState({});
  const [showUpdate, setShowUpdate] = useState(true);
  const [showAdd, setAdd] = useState(true);
  const [bioNotes, setNotes] = useState([]);
  const contxt = useAuthContext();
  const [open, setOpen] = useState(false);
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");
  const [staffId, setStaffId] = useState("");
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [totalDifferent, setTotalDifferent] = useState(0);
  const [totalSuspended, setTotalSuspended] = useState(0);

  useEffect(() => {
    var obj = {};
    if (filterName) {
      obj.name = filterName;
      if (filterName[0]?.length > 2) getIncomingCallData(obj);
    }
    if (filterMobile) obj.mobile = filterMobile;
    if (filterMobile[0]?.length > 9) getIncomingCallData(obj);
  }, [page, rowsPerPage, filterMobile, filterName, type]);

  useEffect(() => {
    getStaffNumber();
    getStateList();
    getDifferentDetail();
    getSuspendedDetail();
  }, []);

  const getIncomingCallData = async (page, rowsPerPage, filter) => {
    setIncomingCallList([]);
    try {
      if (!showDifferent && !showSuspend) {
        let res = await getIncomingCallApi(page, rowsPerPage, filter);
        setIncomingCallList(res?.data?.data?.user);
        setTotalUsers(res?.data?.data?.total);
        if (res?.data?.data?.total === 0) {
          setIsNotFound(true);
        } else {
          setIsNotFound(false);
        }
      }
      if (showDifferent) {
        let res = await DiffrentUserApi();
        const data = res?.data?.data?.diffrent.filter(
          (item) => item.types === "incoming_call"
        );
        setIncomingCallList(data);
      }
      if (showSuspend) {
        let res = await SuspendUserApi();
        const data = res?.data?.data?.diffrent.filter(
          (item) => item.types === "incoming_call"
        );
        setIncomingCallList(data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const getDifferentDetail = async () => {
    try {
      let res = await DiffrentUserApi();
      setTotalDifferent(res.data?.data?.diffrentCount);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getSuspendedDetail = async () => {
    try {
      let res = await SuspendUserApi();
      setTotalSuspended(res?.data?.data?.total);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    setFilterMobile("");
    setFilterName("");
    getIncomingCallData(page, rowsPerPage, filter);
  }, [filter, page, rowsPerPage, type, showDifferent, showSuspend]);

  const getStateList = async () => {
    var filterDetails = await getDetailList();
    let stateList = await getStateListAPI("India");
    setFilterDetail({
      ...filterDetails?.data?.data,
      stateList: stateList.data.data,
    });
    let res1 = await getNotes();
    setNotes(res1?.data?.data);
  };

  const getStaffNumber = async () => {
    let res = await getStaffNumberAPI();
    setStaffNumber(
      res?.data?.data.map((val) => ({ label: val.number, value: val.number }))
    );
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleToggleAll = (rowId) => {
    if (selectedId.includes(rowId)) {
      setSelectedId(selectedId.filter((id) => id !== rowId));
    } else {
      setSelectedId([...selectedId, rowId]);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          {show && (
            <div className="CardNameShow">
              <span>Incoming Section</span>
            </div>
          )}
          <Grid container spacing={4}>
            {incomingGlobalCallList?.map((bioData) => {
              return (
                <Grid item lg={5} md={6} sm={6} xs={12}>
                  <IncomingCard
                    selected={selected.includes(bioData?.id)}
                    onSelectRow={() => onSelectRow(bioData?.id)}
                    update={update}
                    bioData={bioData}
                    setUpdate={setUpdate}
                    setShowUpdate={setShowUpdate}
                    setAdd={setAdd}
                    staffNumber={staffNumber}
                    filterDetail={filterDetail}
                    getIncomingCallData={getIncomingCallData}
                    bioNotes={bioNotes}
                    goToTop={goToTop}
                    selectAllChecked={selectAllChecked}
                    handleToggleAll={handleToggleAll}
                    selectedId={selectedId}
                  />
                </Grid>
              );
            })}
          </Grid>

          <Box display="flex" justifyContent="center" alignItems="center">
            <TableNoData isNotFound={isNotFound} />
          </Box>
          <Box display="grid">
            {!incomingCallList?.length && !isNotFound && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <LoadingScreenSmall />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>

      <TablePaginationCustom
        onChangePageButton={onChangePageButton}
        count={totalUsers}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        dense={dense}
        onChangeDense={onChangeDense}
      />
      <ImportData
        open={open}
        handleClose={handleClose}
        filterDetail={filterDetail}
      />
    </>
  );
}

// ----------------------------------------------------------------------
