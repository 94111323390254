import PropTypes from 'prop-types';
// @mui
import { Dialog, Button, DialogTitle, DialogActions, DialogContent } from '@mui/material';

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.node,
  action: PropTypes.node,
  content: PropTypes.node,
  onClose: PropTypes.func,
};

export default function ConfirmDialog({ title, content, action, open, maxWidth = "md", onClose, ...other }) {
  return (
    <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={onClose} {...other}>
      {/* fullWidth maxWidth={maxWidth ? maxWidth : "xs"} */}
      {title && <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>}
      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      {action &&
        <DialogActions >
          {action}

          {/* <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button> */}
        </DialogActions>
      }
    </Dialog>
  );
}
