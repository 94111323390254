import PropTypes from "prop-types";
import {
  Stack,
  Switch,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Iconify from "../../../../components/iconify";
import { useEffect, useState } from "react";
import { getCountryListAPI, getStateListAPI, staffNameApi } from "src/service";
import Label from "../../../../components/label";

// ----------------------------------------------------------------------

OfflineUserFilter.propTypes = {
  isFiltered: PropTypes.bool,
  filterMobile: PropTypes.string,
  filterName: PropTypes.string,
  filterRole: PropTypes.string,
  onFilterMobile: PropTypes.func,
  onFilterRole: PropTypes.func,
  onResetFilter: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
};

export default function OfflineUserFilter({
  isFiltered,
  setShowDifferent,
  setShowSuspend,
  filterMobile,
  filterName,
  filter,
  setFilter,
  totalUsers,
  onFilterMobile,
  onFilterName,
  onResetFilter,
  getOffineUsers,
  filterDetail,
  numSelected,
  rowCount,
  onSelectAllRows,
  handleFilterApply,
}) {
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");
  const [staffName, setstaffName] = useState([]);
  const [mobvalue, setMobvalue] = useState([]);
  const [nameValue, setNameValue] = useState("");
  const [nameExactValue, setNameExactValue] = useState("");
  const [selectedDateTime, setSelectedDateTime] = useState();
  const [selectedEndDateTime, setSelectedEndDateTime] = useState();
  const [selectedEntryDateTime, setSelectedEntryDateTime] = useState();
  const [selectedEndEntryDateTime, setSelectedEndEntryDateTime] = useState();
  const [selectedDataTypes, setSelectedDataTypes] = useState([]);
  const [StateList, setStateList] = useState([]);
  // const [filteredDiffrentUsers, setFilteredDiffrentUsers] = useState([]);
  console.log("StateList ::::", StateList);
  useEffect(() => {
    setFilter({ ...filter, startDate: selectedDateTime });
  }, [selectedDateTime]);
  useEffect(() => {
    setFilter({ ...filter, endDate: selectedEndDateTime });
  }, [selectedEndDateTime]);

  useEffect(() => {
    setFilter({ ...filter, startEntryDate: selectedEntryDateTime });
  }, [selectedEntryDateTime]);
  useEffect(() => {
    setFilter({ ...filter, endEntryDate: selectedEndEntryDateTime });
  }, [selectedEndEntryDateTime]);

  useEffect(() => {
    getstaffName();
    // DiffrentUser();
  }, []);

  useEffect(() => {
    getStateList();
  }, []);

  const getStateList = async (india) => {
    let res = await getStateListAPI("india");
    setStateList(res?.data?.data);
  };


  const getstaffName = async () => {
    var res = await staffNameApi();
    setstaffName(res?.data?.data);
  };

  const community = [
    { value: "Maheswari Jain", label: "Maheswari Jain" },
    { value: "Digamber Jain", label: "Digamber Jain" },
    { value: "Shwetamber Jain", label: "Shwetamber Jain" },
    { value: "Taran tarn", label: "Taran tarn" },
    { value: "Khandelwal Jain", label: "Khandelwal Jain" },
    { value: "Agrawal Jain", label: "Agrawal Jain" },
    { value: "Gujrati Jain", label: "Gujrati Jain" },
    { value: "Marwadi Jain", label: "Marwadi Jain" },
    { value: "Baniya Jain", label: "Baniya Jain" },
    { value: "Saraf Jain", label: "Saraf Jain" },
    { value: "Gupta Jain", label: "Gupta Jain" },
    { value: "Other Cast", label: "Other Cast" },
  ];

  const jainType = [
    { label: "Maheswari Jain", value: "Maheswari Jain" },
    { label: "Digamber Jain", value: "Digamber Jain" },
    { label: "Shwetamber Jain", value: "Shwetamber Jain" },
    { label: "Taran tarn", value: "Taran tarn" },
    { label: "Khandelwal Jain", value: "Khandelwal Jain" },
    { label: "Agrawal Jain", value: "Agrawal Jain" },
    { label: "Gujrati Jain", value: "Gujrati Jain" },
    { label: "Marwadi Jain", value: "Marwadi Jain" },
    { label: "Baniya Jain", value: "Baniya Jain" },
    { label: "Saraf Jain", value: "Saraf Jain" },
    { label: "Gupta Jain", value: "Gupta Jain" },
    { label: "Other Cast", value: "Other Cast" },
  ];

  const notJain = [
    { label: "Hindu", value: "Hindu" },
    { label: "Muslim", value: "Muslim" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Sindhi", value: "Sindhi" },
    { label: "Isai", value: "Isai" },
  ];

  const maritalStatus = [
    { id: 1, name: "Unmarried" },
    { id: 2, name: "Widow/Widower" },
    { id: 3, name: "Divorced" },
    { id: 4, name: "Awiting divorce" },
  ];

  const groupData = [
    { id: 1, name: "Low Data" },
    { id: 2, name: "Middle Data" },
    { id: 3, name: "High Data" },

  ];


  const specialCase = [
    { label: "Surajmukhi", value: "Surajmukhi" },
    { label: "Out of india ", value: "Out of india " },
    { label: "High profile", value: "High profile" },
    { label: "Divyang", value: "Divyang" },
    {
      label: "2nd Marriage (Divorce,widow)",
      value: "2nd Marriage (Divorce,widow)",
    },
    {
      label: "Not working(10thpass , 12thpass, House Wife)",
      value: "Not working(10thpass , 12thpass, House Wife)",
    },
  ];

  const TypeOfGroup = [
    {
      label: "Only Admin Can Send Information",
      value: "Only Admin Can Send Information",
    },
    { label: "All can Send Information", value: "All can Send Information" },
  ];
  const dataDownload = [
    { label: "First Time Not Used data", value: "First Time Not Used data" },
    { label: "Second Time Not Used data", value: "First Time Not Used data" },
  ];

  // console.log("FILTER :",filteredDiffrentUsers);
  const ageArray = [];
  for (let index = 14; index < 81; index++) {
    ageArray.push({ label: index + " yrs", value: index });
  }

  const task = [
    { label: "all", value: "all" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Call", value: "call" },
    { label: "No Call", value: "no_call" },
    { label: "No activity", value: "no_activity" },
    { label: "Not-Pickup", value: "notPickup" },
    { label: "Not-Connected", value: "notConnected" },
    { label: "Shadi Done", value: "shadiDone" },
    { label: "Register Done", value: "registerDone" },
    { label: "Not Jain", value: "notJain" },
    { label: "Not Interested", value: "notInterested" },
    { label: "Sambandh", value: "samband_click" },
    { label: "Different lang.", value: "different_language" },
  ];

  const OnAppList = [
    { label: "On app", value: "online" },
    { label: "not on app", value: "not_online" },
  ];

  const days = [
    { label: "all", value: "all" },
    { label: "today", value: "today" },
    { label: "yesterday", value: "yesterday" },
    { label: "Before Yesterday", value: "beforeYesterday" },
    { label: "Last 3 Days", value: "lastThreeDays" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const linkEntry = [
    { label: "all", value: "all" },
    { label: "today", value: "today" },
    { label: "yesterday", value: "yesterday" },
    { label: "Before Yesterday", value: "beforeYesterday" },
    { label: "Last 3 Days", value: "lastThreeDays" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const ratings = [
    { label: "all", value: "all" },
    { label: "S", value: "s" },
    { label: "L", value: "l" },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    // { label: "5", value: "5" },
  ];
  const Diiffrent = [
    { label: "Diffrent", value: "offline" },
    { label: "Suspend", value: "offline" },
    { label: "NO FIlter", value: "nofilter" },
  ];
  const userCountry = [
    { label: "India", value: "india" },
    { label: "Out off India", value: "out off india" },
    { label: "All", value: "all" },
  ];
  const Jain = [
    { label: "jain matrimony", value: "Jain Matrimony" },
    { label: "jain basic", value: "Jain Basic" },
    { label: "Jain Special", value: "Jain Special" },
    { label: "Jain Digambar", value: "Jain Digambar" },
    { label: "Jain Shwetambar", value: "Jain Shwetambar" },
    { label: "Hindu Data", value: "Hindu Data" },
    { label: "Hindu & Jain", value: "Hindu & Jain" },
    { label: "Different Language", value: "Different Language" },

    { label: "Don't know", value: "Don't know" },
    { label: "Other", value: "Other" },
  ];

  const methods = useForm({});

  const { getValues } = methods;

  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item lg={2} md={2} sm={6} xs={6}>
            <TextField
              value={startId}
              label="From (से)"
              onChange={(e) => {
                setStartId(e.target.value);
                setFilter({ ...filter, startId: e.target.value });
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={6}>
            <TextField
              value={endId}
              label="To (तक)"
              onChange={(e) => {
                setEndId(e.target.value);
                setFilter({ ...filter, endId: e.target.value });
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={5}>
            <div style={{ position: "relative" }}>
              <TextField
                fullWidth
                // value={filterName}
                // onChange={onFilterName}
                value={nameExactValue}
                onChange={(e) => {
                  setNameExactValue(e?.target?.value);
                  setFilter({ ...filter, name_exact: e.target.value });
                }}
                placeholder="Search Exact Name... (नाम के ठीक से खोजें)"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={5}>
            <div style={{ position: "relative" }}>
              <TextField
                fullWidth
                // value={filterName}
                // onChange={onFilterName}
                value={nameValue}
                onChange={(e) => {
                  setNameValue(e?.target?.value);
                  setFilter({ ...filter, name: e.target.value });
                }}
                placeholder="Search Common Name... (सामान्य नाम खोजें)"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={5}>
            <div style={{ position: "relative" }}>
              <TextField
                fullWidth
                // value={filterMobile}
                // onChange={onFilterMobile}
                value={mobvalue}
                onChange={(e) => {
                  setMobvalue(e.target.value);
                  setFilter({ ...filter, mobile: e.target.value });
                }}
                placeholder="Search Mobile... (मोबाइल खोजें)"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <Label
                sx={{ position: "absolute", right: 10, top: 14 }}
                onClick={() => {
                  getOffineUsers();
                }}
              >
                {totalUsers}
              </Label>
            </div>
          </Grid>
          {/* link entry  */}
          <Grid item lg={3} md={3} sm={3} xs={6}>
            <TextField
              native
              fullWidth
              select
              name=""
              label="Link Entry Timing"
              onChange={(e) => {
                setFilter({ ...filter, group_link_created_at: e.target.value });
              }}
              SelectProps={{
                MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
              }}
              sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
            >
              {linkEntry.map((option) => (
                <MenuItem
                  key={option?.value}
                  value={option?.value}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    borderRadius: 0.75,
                    typography: "body2",
                    textTransform: "capitalize",
                    "&:first-of-type": { mt: 0 },
                    "&:last-of-type": { mb: 0 },
                  }}
                >
                  {option?.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {/* link entry end  */}
          <Grid item lg={2} md={2} sm={2} xs={2}>
            {isFiltered && (
              <Button
                color="error"
                sx={{ flexShrink: 0 }}
                onClick={() => {
                  onResetFilter();
                  getOffineUsers();
                }}
                startIcon={<Iconify icon="mdi:cancel-circle-outline" />}
              >
                Clear
              </Button>
            )}
          </Grid>
        </Grid>

        <Stack
          spacing={2}
          alignItems="center"
          direction={{
            xs: "column",
            sm: "row",
          }}
          sx={{ mt: 1, px: 0 }}
        >
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Timing (समय)"
                onChange={(e) => {
                  setFilter({ ...filter, created_at: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {days.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Task (कार्य)"
                onChange={(e) => {
                  setFilter({ ...filter, task: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {task.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name="call_by_staff"
                label="Call By Staff (कर्मचारी द्वारा कॉल)"
                onChange={(e) => {
                  setFilter({ ...filter, calling_action_id: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {staffName.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.id}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.nameEnglish}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Staff (कर्मचारी)"
                onChange={(e) => {
                  setFilter({ ...filter, entry_id: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {staffName.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.id}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.nameEnglish}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name="ratings"
                label="Ratings (रेटिंग)"
                onChange={(e) => {
                  setFilter({ ...filter, rating: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {ratings?.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.label}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name="user_country"
                label="User Country (प्रयोक्ता देश)"
                onChange={(e) => {
                  let payloadValue;
                  switch (e.target.value) {
                    case "india":
                      payloadValue = "india";
                      break;
                    case "out off india":
                      payloadValue = "out_of_india";
                      break;
                    default:
                      payloadValue = "all";
                  }
                  setFilter({ ...filter, user_country: payloadValue });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {userCountry?.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name="Diffrent Language"
                label="Diiffrent (विभिन्न)"
                onChange={(e) => {
                  setFilter({ ...filter, rating: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {Diiffrent?.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.label}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                    onClick={() => {
                      if (option.label === "Diffrent") {
                        setShowSuspend(false);
                        setShowDifferent(true);
                      } else if (option.label === "Suspend") {
                        setShowDifferent(false);
                        setShowSuspend(true);
                      } else if (option.label === "NO FIlter") {
                        setShowDifferent(false);
                        setShowSuspend(false);
                      }
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name="On App"
                label="Select OnApp (ऐप पर चयन करें)"
                onChange={(e) => {
                  setFilter({ ...filter, onApp: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {OnAppList?.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name="Diffrent Language"
                label="DataType (डेटा प्रकार)"
                onChange={(e) => {
                  setFilter({ ...filter, type_of_data: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {Jain?.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.label}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                    onClick={() => {
                      if (option.label === "jain matrimony") {
                        setShowSuspend(false);
                        setShowDifferent(false);
                      } else if (option.label === "jain basic") {
                        setShowDifferent(false);
                        setShowSuspend(false);
                      } else if (option.label === "jain special") {
                        setShowDifferent(false);
                        setShowSuspend(false);
                      } else if (option.label === "jain digamber") {
                        setShowDifferent(false);
                        setShowSuspend(false);
                      } else if (option.label === " jain shweatember") {
                        setShowDifferent(false);
                        setShowSuspend(false);
                      }
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}
            {/* data types multiple select */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                select
                multiple
                fullWidth
                name="Diffrent Language"
                label="DataType (डेटा प्रकार)"
                value={selectedDataTypes}
                onChange={(e) => {
                  const value = e.target.value;
                  setSelectedDataTypes(value);
                  setFilter({ ...filter, type_of_data: value });

                  const lastSelectedValue = value[value.length - 1];
                  if (lastSelectedValue === "jain matrimony") {
                    setShowSuspend(false);
                    setShowDifferent(false);
                  } else if (lastSelectedValue === "jain basic") {
                    setShowDifferent(false);
                    setShowSuspend(false);
                  } else if (lastSelectedValue === "Jain Special") {
                    setShowDifferent(false);
                    setShowSuspend(false);
                  } else if (lastSelectedValue === "Jain Digambar") {
                    setShowDifferent(false);
                    setShowSuspend(false);
                  } else if (lastSelectedValue === "Jain Shwetambar") {
                    setShowDifferent(false);
                    setShowSuspend(false);
                  }
                }}
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => selected.join(', '),
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {Jain.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={selectedDataTypes.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* data types multiple select end*/}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                label="Call Start Date (कॉल प्रारंभ तिथि)"
                type="date"
                fullWidth
                size={"medium"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedDateTime}
                onChange={(e) => setSelectedDateTime(e.target.value)}
                name="date"
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                label="Call End Date (कॉल समाप्ति तिथि)"
                type="date"
                fullWidth
                size={"medium"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedEndDateTime}
                onChange={(e) => setSelectedEndDateTime(e.target.value)}
                name="date"
              />
            </Grid>
            {/* start entry date  */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                label=" Start Entry Date"
                type="date"
                fullWidth
                size={"medium"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedEntryDateTime}
                onChange={(e) => setSelectedEntryDateTime(e.target.value)}
                name="date"
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                label=" End Entry  Date "
                type="date"
                fullWidth
                size={"medium"}
                InputLabelProps={{
                  shrink: true,
                }}
                value={selectedEndEntryDateTime}
                onChange={(e) => setSelectedEndEntryDateTime(e.target.value)}
                name="date"
              />
            </Grid>
            {/* JAIN TYPE */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Jain Type"
                onChange={(e) => {
                  setFilter({ ...filter, other_cast_type: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {jainType.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* Not Jain  */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Not Jain"
                onChange={(e) => {
                  setFilter({ ...filter, not_jain_type: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {notJain.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* specail case */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Special case"
                onChange={(e) => {
                  setFilter({ ...filter, special_case: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {specialCase.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* type of group */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Type of Group"
                onChange={(e) => {
                  setFilter({ ...filter, group_type: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {TypeOfGroup.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* data download ka filter  */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Data Downlaod Filter"
                onChange={(e) => {
                  setFilter({ ...filter, group_type: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {dataDownload.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* state */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                fullWidth
                select
                name="state"
                label="State"
                onChange={(e) => {
                  setFilter({ ...filter, state: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {StateList?.map((option) => (
                  <MenuItem
                    key={option?.id}
                    value={option?.english}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.english}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* community */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Community"
                onChange={(e) => {
                  setFilter({ ...filter, other_cast_type: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {community.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* maritial status  */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                fullWidth
                select
                name="maritial status"
                label="maritial status"
                onChange={(e) => {
                  setFilter({ ...filter, marital_status: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {maritalStatus?.map((i) => (
                  <MenuItem
                    key={i?.id}
                    value={i.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {i.name}
                  </MenuItem>
                ))}

              </TextField>
            </Grid>
            {/* group data  */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                fullWidth
                select
                name="group data type"
                label="Group Data Type"
                onChange={(e) => {
                  setFilter({ ...filter, marital_status: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {groupData?.map((i) => (
                  <MenuItem
                    key={i?.id}
                    value={i.name}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {i.name}
                  </MenuItem>
                ))}

              </TextField>
            </Grid>
          </Grid>
        </Stack>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleFilterApply}
            sx={{ typography: "body1", py: 2, mt: 1 }}
          >
            Apply Filter
          </Button>
        </div>
        {/* <Paper sx={{ borderRadius: 1, pl: 1, mt: 1, bgcolor: 'background.neutral' }}>

                <Stack direction="row" spacing={2}>
                    <Stack direction="row">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={(event) => onSelectAllRows(event.target.checked)}
                        />
                        <Typography variant="overline" sx={{ mt: 1.5, display: 'block', color: 'text.secondary', whiteSpace: "NoWrap" }}>Select All</Typography>
                    </Stack>

                    <Grid item xl={1} lg={1} md={2} sm={3} xs={6} mb={{ sm: 1, xs: 1 }}>
                        <Button variant="contained" color="inherit" size='small' sx={{ mt: 0.5, whiteSpace: "nowrap" }} >
                            Move To Hindu
                        </Button>
                    </Grid>
                </Stack>
            </Paper> */}
      </>
    </>
  );
}
