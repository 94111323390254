import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Grid, Card, Table, Button, Item, Autocomplete, InputLabel, MenuItem, CardContent, TableBody, Typography, Chip, Select, CardHeader, TableContainer, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Modal, Stack, CardMedia, CardActions, } from '@mui/material';
import LoadingScreenSmall from "src/components/loading-screen/LoadingScreenSmall";
import { useEffect } from "react";
import { getDetailList, getOnlineUserList, getPushNotification, SendPushNotificationById, taskAssignFromOnlineUsers, staffNameApi, getStaffNumberAPI, getStateListAPI, getOnlineUserAnalytics, getNotes, getPayPackgeApi, excelDownloadonlineUser } from 'src/service';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
// import XLSX from 'xlsx';
import { writeFile } from 'xlsx';
import axios from 'axios';
import * as XLSX from 'xlsx'; // Import the xlsx module
import { saveAs } from 'file-saver';
import { RHFAutocomplete } from 'src/components/hook-form';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CloseIcon from '@mui/icons-material/Close';
import HistoryIcon from '@mui/icons-material/History';
import AddIcon from '@mui/icons-material/Add';
import { PushNotificationCard } from 'src/sections/@dashboard/user/cards/PushNotificationCard';
import constant, { currentAppVersion } from 'src/Constant';
import { TableNoData, useTable } from 'src/components/table';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { UserTableRow } from '../list';


const ROLE_OPTIONS = [
    'id',
    'name',
    'mobile'
];

const CardButtonStyles = {
    backgroundColor: '#c4cdd5',
    color: '#3e43eb',
    marginRight: '0.5em',
    borderRadius: '25px',
    '&:hover': {
        backgroundColor: '#c4cdd5',
    },
};

const scheduleOption = [
    { label: "Once", value: "once" },
    { label: "Repeat", value: "repeat" }
];

const buttonStyle = {
    borderRadius: '50%',
    margin: '0 1px',
    minWidth: '20px',
    minHeight: '20px',
};

const circularButtonStyle = {
    borderRadius: '50%',
    width: '2em',
    height: '2em',
    padding: 0,
    minWidth: 0,
};

const centerIconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

const TABLE_HEAD = [
    // { id: 'Id', label: 'Id', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'Mob-DOB', label: 'Mob-DOB', align: 'left' },
    { id: 'Registration', label: 'Registration', align: 'left' },
    { id: 'Payment/Approval', label: 'Pay/Approval', align: 'left' },
    { id: 'Call', label: 'Call', align: 'center' },
    { id: 'Rating', label: 'Rating', align: 'left' },
    { id: 'Number', label: 'Mobile', align: 'left' },
    { id: 'Note', label: "", align: 'left' },
];

// ----------------------------------------------------------------------

export default function OnlineGlobalCard({ onlineUserList, type, show }) {
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const { dense, page, order, orderBy, rowsPerPage, setPage, selected, setSelected, onSelectRow, onSelectAllRows, onSort, onChangeDense, onChangePage, onChangeRowsPerPage, } = useTable();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [filterDetail, setFilterDetail] = useState({});
    const [openConfirm, setOpenConfirm] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [filterRole, setFilterRole] = useState('mobile');
    const [filterStatus, setFilterStatus] = useState('all');
    const [filter, setFilter] = useState({});
    const [open, setOpen] = useState(false);
    const [check, setCheck] = useState(false);
    const [showPushNotif, setShowPushNotif] = useState(false);
    const [notes, setNotes] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [staffNumber, setStaffNumber] = useState([]);
    const [selectedStaffId, setSelectedStaffId] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const contxt = useAuthContext();
    const [payPackge, setPayPackge] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [startId, setStartId] = useState('');
    const [endId, setEndId] = useState('');
    const [staffName, setStaffName] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalLatestUser, setTotalLatestUser] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selectNotification, setSelectNotification] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedDateTime, setSelectedDateTime] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [notificationList, setNotificationList] = useState([]);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [selectedDays, setSelectedDays] = useState([false, false, false, false, false, false, false]);
    const [timePickers, setTimePickers] = useState([
        { id: 1, value: '' },
    ]);
    const [checkedValues, setCheckedValues] = useState([]);

    useEffect(() => {
        var obj = {}
        if (filterName) obj[filterRole] = filterName
        if (filterRole === "mobile") {
            if (filterName.length > 8 || filterName.length === 0) {
                getUser(obj);
            }
        } else {
            getUser(obj);
        }
    }, [page, filterName, filterRole, type]);

    useEffect(() => {
        setTableData([]);
    }, [location]);

    useEffect(() => {
        setFilterName("")
        getUser(filter)
        getStaffNumber()
        getStateList()
    }, [filter, page, rowsPerPage, type]);

    useEffect(() => {
        getOnlineUserAnalyticsApi();
        getNotificationList()
    }, []);

    const getOnlineUserAnalyticsApi = async () => {
        let res = await getOnlineUserAnalytics();
        setAnalytics(res?.data?.data);
    }

    const getNotificationList = async () => {
        const res = await getPushNotification();
        setNotificationList(res?.data?.data?.excelHistory);
    };

    const getStaffName = async () => {
        try {
            const res = await staffNameApi();
            setStaffName(res?.data?.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getStaffName();
    }, []);


    const getStateList = async () => {
        var filterDetails = await getDetailList()
        let stateList = await getStateListAPI("India")
        setFilterDetail({ ...filterDetails?.data?.data, stateList: stateList.data.data })
        let res1 = await getNotes()
        setNotes(res1?.data?.data);
    }

    const getUser = async (filter) => {
        filter.type = type;
        let res = await getOnlineUserList(page, rowsPerPage, filter)
        // console.log("DATA :", res?.data?.data?.totalAppVersion)
        setTotalLatestUser(res?.data?.data?.totalAppVersion);
        setTableData(res?.data?.data?.onlineUsers);
        setTotalUsers(res?.data?.data?.total)
        if (res?.data?.data?.onlineUsers?.length === 0) setIsNotFound(true)
        else setIsNotFound(false)
    };

    const getStaffNumber = async () => {
        let res = await getStaffNumberAPI({ type: "onlineUser" })
        setStaffNumber(res?.data?.data.map(val => ({ label: val.number, value: val.number })));
    };


    const isFiltered = filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

    const isValid = (id) => {
        return checkedValues.includes(id);
    };

    const handleToggleAll = () => {
        if (selectAllChecked) {
            setSelected([]);
        } else {
            const allIds = tableData.map((row) => row.id);
            setSelected(allIds);
        }
        setSelectAllChecked(!selectAllChecked);
    };

    const handleToggleOne = (rowId) => {
        if (selected.includes(rowId)) {
            setSelected(selected.filter(id => id !== rowId));
        } else {
            setSelected([...selected, rowId]);
        }
    };

    const handleClose = () => {
        setOpen(!open);
        getUser(filter)
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleShow = () => {
        if (check === true) {
            setCheck(false);
        }
        if (check === false) {
            setCheck(true);
        }
    }
    const handlePushNotifChange = () => {
        if (showPushNotif === true) {
            setShowPushNotif(false);
            setSelectedValue(null)
        }
        if (showPushNotif === false) {
            setShowPushNotif(true);
        }
    }

    const sendNotification = async () => {
        let obj = {
            "selectedUserIds": selected,
            "id": selectNotification
        }
        try {
            const res = await SendPushNotificationById(obj);
            // console.log("RESPONSE :", res);
            if (res?.status == 200) {
                enqueueSnackbar(res?.data?.message);
                setSelected([]);
            }
        } catch (error) {
            enqueueSnackbar(error?.error, { variant: 'error' });
        }
    }

    const handleScheduleChange = (event, newValue) => {
        setSelectedValue(newValue);
    };

    const handleDayChange = (index) => {
        const updatedSelectedDays = [...selectedDays];
        updatedSelectedDays[index] = !updatedSelectedDays[index];
        setSelectedDays(updatedSelectedDays);
    }

    const handleTimeChange = (id, newValue) => {
        const updatedTimePickers = timePickers.map((picker) =>
            picker.id === id ? { ...picker, value: newValue } : picker
        );
        setTimePickers(updatedTimePickers);
    };

    const addTimePicker = () => {
        const newId = timePickers.length + 1;
        setTimePickers([...timePickers, { id: newId, value: '' }]);
    };

    const handleFilterName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleFilterRole = (event) => {
        setFilterName("")
        setPage(0);
        setFilterRole(event.target.value);
    };

    const handleDeleteRow = async (id) => {
        const deleteRow = tableData.filter((row) => row.id !== id);
        setTableData(deleteRow);
    };

    const updateTable = async (row) => {
        const usersListTemp = [...tableData];
        const findRow = usersListTemp.findIndex((r) => r.id === row.id);
        usersListTemp[findRow] = row;
        setTableData(usersListTemp);
    };

    const handleEditRow = (id) => {
        navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
    };

    const handleResetFilter = () => {
        setFilterName('');
        setFilterStatus('');
    };

    useEffect(() => {
        getPayPackge();
    }, [])

    const getPayPackge = async () => {
        var res = await getPayPackgeApi();
        setPayPackge(res?.data?.data)
    }
    return (

        <div className='main-body'>
            <Card style={{ marginTop: 65 }}>
                <CardContent sx={{ py: 0, px: 2 }}>
                    <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                        <Scrollbar>
                            <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800, mt: 1 }}>
                                <TableBody>
                                    {onlineUserList?.map((row) => (
                                        <UserTableRow
                                            key={row?.id}
                                            row={row}
                                            payPackge={payPackge}
                                            staffNumber={staffNumber}
                                            selected={selected.includes(row?.id)}
                                            onSelectRow={() => onSelectRow(row?.id)}
                                            onDeleteRow={() => handleDeleteRow(row?.id)}
                                            onEditRow={() => handleEditRow(row?.name?.english)}
                                            updateTable={updateTable}
                                            type={{ type }}
                                            notes={notes}
                                            setFilter={setFilter}
                                            isValid={isValid}
                                            getOnlineUserAnalyticsApi={getOnlineUserAnalyticsApi}
                                            check={check}
                                            showPushNotif={showPushNotif}
                                            handleToggleAll={handleToggleAll}
                                            selectAllChecked={selectAllChecked}
                                            handleToggleOne={handleToggleOne}
                                            globalshow={show}
                                        />
                                    ))}

                                    <TableNoData isNotFound={isNotFound} />
                                </TableBody>
                            </Table>
                        </Scrollbar>
                        {!onlineUserList?.length && !isNotFound &&
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <LoadingScreenSmall />
                            </Box>
                        }
                    </TableContainer>
                </CardContent>
            </Card >
        </div >
    );
}
