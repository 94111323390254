import PropTypes from "prop-types";
import {
  Stack,
  Switch,
  InputAdornment,
  TextField,
  MenuItem,
  Button,
  Box,
  Paper,
  Typography,
  Grid,
  Checkbox,
} from "@mui/material";
import { useForm } from "react-hook-form";
import Iconify from "../../../../components/iconify";
import { useEffect, useState } from "react";
import { getCountryListAPI, staffNameApi } from "src/service";
import Label from "../../../../components/label";
import { useAuthContext } from "src/auth/useAuthContext";

// ----------------------------------------------------------------------

IncomingUserFilter.propTypes = {
  isFiltered: PropTypes.bool,
  filterMobile: PropTypes.string,
  filterName: PropTypes.string,
  filterRole: PropTypes.string,
  onFilterMobile: PropTypes.func,
  onFilterRole: PropTypes.func,
  onResetFilter: PropTypes.func,
  optionsRole: PropTypes.arrayOf(PropTypes.string),
};

export default function IncomingUserFilter({
  isFiltered,
  setShowDifferent,
  setShowSuspend,
  filterMobile,
  filterName,
  filter,
  setFilter,
  totalUsers,
  onFilterMobile,
  onFilterName,
  onResetFilter,
  getOffineUsers,
  filterDetail,
  numSelected,
  rowCount,
  onSelectAllRows,
  handleFilterApply,
}) {
  const [startId, setStartId] = useState("");
  const [endId, setEndId] = useState("");
  const [staffName, setstaffName] = useState([]);
  const [mobvalue, setMobvalue] = useState([]);
  const [nameValue, setNameValue] = useState("");
  const { user } = useAuthContext();
  const { sections } = user;
  // const [filteredDiffrentUsers, setFilteredDiffrentUsers] = useState([]);

  useEffect(() => {
    getstaffName();
    // DiffrentUser();
  }, []);

  const getstaffName = async () => {
    var res = await staffNameApi();
    setstaffName(res?.data?.data);
  };

  // console.log("FILTER :",filteredDiffrentUsers);
  const ageArray = [];
  for (let index = 14; index < 81; index++) {
    ageArray.push({ label: index + " yrs", value: index });
  }

  const jainType = [
    { label: "Maheswari Jain", value: "Maheswari Jain" },
    { label: "Digamber Jain", value: "Digamber Jain" },
    { label: "Shwetamber Jain", value: "Shwetamber Jain" },
    { label: "Taran tarn", value: "Taran tarn" },
    { label: "Khandelwal Jain", value: "Khandelwal Jain" },
    { label: "Agrawal Jain", value: "Agrawal Jain" },
    { label: "Gujrati Jain", value: "Gujrati Jain" },
    { label: "Marwadi Jain", value: "Marwadi Jain" },
    { label: "Baniya Jain", value: "Baniya Jain" },
    { label: "Saraf Jain", value: "Saraf Jain" },
    { label: "Gupta Jain", value: "Gupta Jain" },
    { label: "Other Cast", value: "Other Cast" },
  ];

  const notJain = [
    { label: "Hindu", value: "Hindu" },
    { label: "Muslim", value: "Muslim" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Sindhi", value: "Sindhi" },
    { label: "Isai", value: "Isai" },
  ];

  const specialCase = [
    { label: "Surajmukhi", value: "Surajmukhi" },
    { label: "Out of india ", value: "Out of india " },
    { label: "High profile", value: "High profile" },
    { label: "Divyang", value: "Divyang" },
    { label: "2nd Marriage (Divorce,widow)", value: "2nd Marriage (Divorce,widow)" },
    { label: "Not working(10thpass , 12thpass, House Wife)", value: "Not working(10thpass , 12thpass, House Wife)" },
  ];

  const task = [
    { label: "all", value: "all" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Call", value: "call" },
    { label: "No Call", value: "noCall" },
    { label: "Not-Pickup", value: "notPickup" },
    { label: "Not-Connected", value: "notConnected" },
    { label: "Shadi Done", value: "shadiDone" },
    { label: "Register Done", value: "registerDone" },
    { label: "Not Jain", value: "notJain" },
    { label: "Not Interested", value: "notInterested" },
  ];


  const days = [
    { label: "all", value: "all" },
    { label: "today", value: "today" },
    { label: "yesterday", value: "yesterday" },
    { label: "Before Yesterday", value: "beforeYesterday" },
    { label: "Last 3 Days", value: "lastThreeDays" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const ratings = [
    { label: "all", value: "all" },
    { label: "S", value: "s" },
    { label: "L", value: "l" },
    { label: "0", value: "0" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
  ];

  const methods = useForm({});

  const { getValues } = methods;

  return (
    <>
      <>
        <Grid container spacing={2}>
          <Grid item lg={2} md={2} sm={6} xs={6}>
            <TextField
              value={startId}
              label="From (से)"
              onChange={(e) => {
                setStartId(e.target.value);
                setFilter({ ...filter, startId: e.target.value });
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={6} xs={6}>
            <TextField
              value={endId}
              label="To (को)"
              onChange={(e) => {
                setEndId(e.target.value);
                setFilter({ ...filter, endId: e.target.value });
              }}
              sx={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={5}>
            <div style={{ position: "relative" }}>
              <TextField
                fullWidth
                // value={filterName}
                // onChange={onFilterName}
                value={nameValue}
                onChange={(e) => {
                  setNameValue(e?.target?.value);
                  setFilter({ ...filter, name: e.target.value });
                }}
                placeholder="Search Name...(नाम खोजें)"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={5} xs={5}>
            <div style={{ position: "relative" }}>
              <TextField
                fullWidth
                // value={filterMobile}
                // onChange={onFilterMobile}
                value={mobvalue}
                onChange={(e) => {
                  setMobvalue(e.target.value);
                  setFilter({ ...filter, mobile: e.target.value });
                }}
                placeholder="Search Mobile...(मोबाइल खोजें)"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Iconify
                        icon="eva:search-fill"
                        sx={{ color: "text.disabled" }}
                      />
                    </InputAdornment>
                  ),
                }}
              ></TextField>
              <Label
                sx={{ position: "absolute", right: 10, top: 14 }}
                onClick={() => {
                  getOffineUsers();
                }}
              >
                {totalUsers}
              </Label>
            </div>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2}>
            {isFiltered && (
              <Button
                color="error"
                sx={{ flexShrink: 0 }}
                onClick={() => {
                  onResetFilter();
                  getOffineUsers();
                }}
                startIcon={<Iconify icon="mdi:cancel-circle-outline" />}
              >
                Clear
              </Button>
            )}
          </Grid>
        </Grid>

        <Stack
          spacing={2}
          alignItems="center"
          direction={{
            xs: "column",
            sm: "row",
          }}
          sx={{ mt: 1, px: 0 }}
        >
          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Timing (समय)"
                onChange={(e) => {
                  setFilter({ ...filter, created_at: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {days.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Task (कार्य)"
                onChange={(e) => {
                  setFilter({ ...filter, task: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {task.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {sections && sections?.IncomingUsers["All Data"] && (
              <Grid item lg={3} md={3} sm={3} xs={6}>
                <TextField
                  native
                  fullWidth
                  select
                  name=""
                  label="Staff (कर्मचारी)"
                  value={
                    staffName?.find((o) => o?.id === filter?.entry_id)
                      ?.nameEnglish
                  }
                  onChange={(e) => {
                    setFilter({ ...filter, entry_id: e.target.value });
                  }}
                  SelectProps={{
                    MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                  }}
                  sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
                >
                  {staffName.map((option) => (
                    <MenuItem
                      key={option?.value}
                      value={option?.id}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: "body2",
                        textTransform: "capitalize",
                        "&:first-of-type": { mt: 0 },
                        "&:last-of-type": { mb: 0 },
                      }}
                    >
                      {option?.nameEnglish}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name="ratings"
                label="Ratings (मूल्यांकन)"
                onChange={(e) => {
                  setFilter({ ...filter, rating: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {ratings?.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.label}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Entry Number"
                onChange={(e) => {
                  setFilter({ ...filter, entry_number: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {[

                  { value: "7610447511", label: "7610447511" },
                  { value: "7610447512", label: "7610447512" },
                  { value: "7581839333", label: "7581839333" },
                  { value: "7583831009", label: "7583831009" },
                  { value: "7583831008", label: "7583831008" },
                ]?.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* JAIN TYPE */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Jain Type"
                onChange={(e) => {
                  setFilter({ ...filter, other_cast_type: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {jainType.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* Not Jain  */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Not Jain"
                onChange={(e) => {
                  setFilter({ ...filter, not_jain_type: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {notJain.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* specail case */}
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                native
                fullWidth
                select
                name=""
                label="Special case"
                onChange={(e) => {
                  setFilter({ ...filter, special_case: e.target.value });
                }}
                SelectProps={{
                  MenuProps: { PaperProps: { sx: { maxHeight: 260 } } },
                }}
                sx={{ maxWidth: { sm: 410 }, textTransform: "capitalize" }}
              >
                {specialCase.map((option) => (
                  <MenuItem
                    key={option?.value}
                    value={option?.value}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: "body2",
                      textTransform: "capitalize",
                      "&:first-of-type": { mt: 0 },
                      "&:last-of-type": { mb: 0 },
                    }}
                  >
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </Stack>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleFilterApply}
            sx={{ typography: "body1", py: 2, mt: 1 }}
          >
            Apply Filter
          </Button>
        </div>
        {/* <Paper sx={{ borderRadius: 1, pl: 1, mt: 1, bgcolor: 'background.neutral' }}>

                <Stack direction="row" spacing={2}>
                    <Stack direction="row">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={(event) => onSelectAllRows(event.target.checked)}
                        />
                        <Typography variant="overline" sx={{ mt: 1.5, display: 'block', color: 'text.secondary', whiteSpace: "NoWrap" }}>Select All</Typography>
                    </Stack>

                    <Grid item xl={1} lg={1} md={2} sm={3} xs={6} mb={{ sm: 1, xs: 1 }}>
                        <Button variant="contained" color="inherit" size='small' sx={{ mt: 0.5, whiteSpace: "nowrap" }} >
                            Move To Hindu
                        </Button>
                    </Grid>
                </Stack>
            </Paper> */}
      </>
    </>
  );
}
